import "./core/AlertCore.js";
import "./core/AlertDiagnosticTile.js";
import "./core/AlertDriverTile.js";
import { driverAnalytics, hardwareAnalytics } from "./drivers-diagnostics-analytics.js";
import { dellMetricsTrackProp20 } from "../analytics/hero-section.js";
import { supportStateAnalytics } from "../analytics/supporthome-state.js";

const driversAndDiagnostics = document.getElementById("divDriversAndDiagnostics");
var thisPcUrl = "";
var stCookies = "";
var productName = "";
var checkAgain = "",
    lastChecked = "",
    lastScan = "",
    scanNow = "",
    runScanAutomatically = "",
    loadingError = "",
    tryAgain,
    findUpdate = "",
    driversUpdate = "",
    hardware = "",
    viewResult = "",
    noIssueFound = "",
    critical = "",
    recommended = "",
    isMobile = "True",
    issueFound = "",
    driversCurrentStage = "",
    hardwareCurrentStage = "",
    runQuickScan = "",
    findDriversWin11 = "",
    findDrivers = "",
    findDriversStCookie = "",
    driverDownloadsWin11 = "",
    findDiagNoAdmin = "",
    findDiagNoAdminConsumer = "",
    isDriverLoaded = false,
    isHardWareLoaded = false,
    isSupportedOs = "",
    isSupportedBrowser = "";


if (driversAndDiagnostics) {
    thisPcUrl = driversAndDiagnostics.dataset.thispcurl;
    stCookies = driversAndDiagnostics.dataset.stcookies;
    productName = driversAndDiagnostics.dataset.productname;
    checkAgain = driversAndDiagnostics.dataset.checkagain;
    lastChecked = driversAndDiagnostics.dataset.lastchecked;
    lastScan = driversAndDiagnostics.dataset.lastscan;
    scanNow = driversAndDiagnostics.dataset.scannow;
    runScanAutomatically = driversAndDiagnostics.dataset.runscanautomatically;
    loadingError = driversAndDiagnostics.dataset.loadingerror;
    tryAgain = driversAndDiagnostics.dataset.tryagain;
    findUpdate = driversAndDiagnostics.dataset.findupdate;
    driversUpdate = driversAndDiagnostics.dataset.driversupdate;
    hardware = driversAndDiagnostics.dataset.hardware;
    viewResult = driversAndDiagnostics.dataset.viewresult;
    noIssueFound = driversAndDiagnostics.dataset.noissuefound;
    critical = driversAndDiagnostics.dataset.critical;
    recommended = driversAndDiagnostics.dataset.recommended;
    isMobile = driversAndDiagnostics.dataset.ismobile;
    issueFound = driversAndDiagnostics.dataset.issuefound;
    runQuickScan = driversAndDiagnostics.dataset.runquickscan;
    findDriversWin11 = driversAndDiagnostics.dataset.finddriverswin11;
    findDrivers = driversAndDiagnostics.dataset.finddrivers;
    findDriversStCookie = driversAndDiagnostics.dataset.finddriversstcookie;
    driverDownloadsWin11 = driversAndDiagnostics.dataset.driverdownloadswin11;
    findDiagNoAdmin = driversAndDiagnostics.dataset.finddiagnoadmin;
    findDiagNoAdminConsumer = driversAndDiagnostics.dataset.finddiagnoadminConsumer;
    isSupportedOs = driversAndDiagnostics.dataset.issupportedos;
    isSupportedBrowser = driversAndDiagnostics.dataset.issupportedbrowser;
}

function GetDriverDiagnostics() {
    const isAdmin = window.sessionStorage.getItem("IsAdmin");
    if (isMobile === "False" && isSupportedOs === "True" && isSupportedBrowser === "True") {
        const divDriversDiagnosticsHot = document.getElementById("divDriversDiagnosticsHot");
        if (divDriversDiagnosticsHot !== null && divDriversDiagnosticsHot !== undefined) {
            divDriversDiagnosticsHot.classList.remove("dds__d-none");
        }
        if (isAdmin === null || (isAdmin !== undefined && isAdmin.toLowerCase() === "true")) {
            Promise.all([
                    renderSaScript(driversAndDiagnostics.dataset.supportassistconfigurl),
                    renderSaScript(driversAndDiagnostics.dataset.dsdapiurlnojq)
                ])
                .then(() => {
                    AlertDriverTile.getDriverData(function(success) { bindDriversHtml(success); });
                    AlertDiagnosticTile.getDiagnosticData(function(success) { bindDiagnosticsHtml(success); });
                })
                .catch((error) => {
                    console.error(`eSupportClientApi or SupportAssistClientJson not loaded :- ${error}`);
                    supportStateAnalytics('cold');
                });
        } else if (isAdmin !== undefined && isAdmin.toLowerCase() === "false"){
            adminAndConsumerMessage(true, false);
        }
    }
    else {
        supportStateAnalytics('cold');
        const divDriversDiagnosticsCold = document.getElementById("divDriversDiagnosticsCold");
        const divDriversDiagnosticsHot = document.getElementById("divDriversDiagnosticsHot");
        if (divDriversDiagnosticsCold !== null && divDriversDiagnosticsCold !== undefined) {
            divDriversDiagnosticsCold.classList.remove("dds__d-none");
        }
        if (divDriversDiagnosticsHot !== null && divDriversDiagnosticsHot !== undefined) {
            divDriversDiagnosticsHot.classList.add("dds__d-none");
        }
    }
}


function adminAndConsumerMessage(showAdminMessage, isBusinessVersion) {
    const divDriversCardBody = document.getElementById('divDriversCardBody');
    document.getElementById("divDriversDiagnosticsCold").classList.remove("dds__d-none");
    divDriversCardBody.classList.add('dds__pb-5');
    divDriversCardBody.classList.remove('dds__pb-3');

    if (showAdminMessage) {
        if (isBusinessVersion) {
            document.getElementById('divNoAdminMsgConsr').classList.remove('dds__d-none');
        }
        else {
            document.getElementById('divNoAdminMsg').classList.remove('dds__d-none');
        }
        const divDriversCardAction = document.querySelector('.divDriversCardAction');
        const scanHardwareLink = document.getElementById('scanHardwareLink');
        const scanHardwareBtn = document.getElementById('scanHardwareBtn');
        divDriversCardBody.classList.remove('dds__pb-5');
        divDriversCardBody.classList.add('dds__pb-2');
        divDriversCardAction.classList.add('dds__mt-1');
        divDriversCardAction.classList.remove('dds__mt-3');
        document.getElementById('updateDriversLink').innerText = `${findDrivers}`;
        scanHardwareBtn.innerText = `${findDiagNoAdmin}`;
        scanHardwareLink.classList.add("dds__d-none");
        scanHardwareBtn.classList.remove("dds__d-none");
    }

    document.getElementById("divDriversDiagnosticsHot").classList.add("dds__d-none");

    isDriverLoaded = true;
    supportState();
}

function bindDriversHtml(success) {
    let showAdminMessage = false;

    let isBusinessVersion = false;
    if (((success.saType != undefined && success.saType != null) &&
        (success.saType.toLowerCase() === "sacomm" || success.saType === "secureconnectclient"))) { 
        isBusinessVersion = true;
        driversCurrentStage = "sabusinessversion";
    }
    if (!checkAdminsRights(success) || isBusinessVersion) {  
        success.isSupportAssistAvailable = false;
        showAdminMessage = true;
    }

    if (success != null && success.driverData != null && success.isSupportAssistAvailable === true) {
        let driversHtml = "";
        const driversData = success.driverData;
        let criticalRecommendedData = "";
        let iconHtml = "";
        let linkHtml = "";
        let dateHtml = "";
        let colXl = 6;
        let isDriversDataEmpty = false;
        if ((driversData.lastScanDaysPassed === null || driversData.lastScanDaysPassed === '') &&
            (driversData.lastScanRunTime === null || driversData.lastScanRunTime === '')) {
            isDriversDataEmpty = true;
        }
        if (stCookies === null || stCookies === "") {
            thisPcUrl = driversData.driverScanInitiateUrl;
        }
        const isExceptionEmpty = Object.values(success.exception).every(x => x === null || x === '');

        if (isExceptionEmpty) {
            if (success.hideDriverAlertforWin11) {
                if (stCookies !== null && stCookies !== "") {
                    criticalRecommendedData = `${findDriversStCookie} <span id="dndProductInfo">${getProductName()}</span>`;
                } else {
                    criticalRecommendedData = `${findDriversWin11}`;
                }
                driversUpdate = `${driverDownloadsWin11}`;
                iconHtml = `<i class="dds__icon dds__icon--alert-info-cir dds__rounded-circle dds__p-1" style="color: #0672CB;" aria-hidden="true"></i>`;
                linkHtml = `<a href="${driversData.driverScanInitiateUrl}" class="dds__link--standalone dds__button dds__button--block dds__button--secondary dds__button--sm">${findDrivers}</a>`;
                driversCurrentStage = "driveralertwin11";
            } else {
                if (!isDriversDataEmpty) {
                    if (driversData.lastScanDaysPassed <= 7) {
                        criticalRecommendedData = [driversData.critical !== "" ? `${driversData.critical} ${critical}` : "", driversData.recommended !== "" ? `${driversData.recommended} ${recommended}` : ""].filter(Boolean).join(", ");
                        iconHtml = `<i class="dds__icon  dds__icon--alert-notice dds__rounded-circle dds__p-1" style="color: #CE8D00;"  aria-hidden="true"></i>`;
                        linkHtml = `<a href="${driversData.driverScanInitiateUrl}" style="text-align:center" class="ancDriversCheckAgain dds__link--standalone dds__button dds__button--block dds__button--secondary dds__button--sm">${checkAgain}</a>`;
                        dateHtml = `<span class="dds__row">${lastChecked}: ${dateConversion(driversData.lastScanRunTime)}</span>`;
                        driversCurrentStage = "within7days";
                    }

                    if (driversData.lastScanDaysPassed > 7) {
                        criticalRecommendedData = `${lastScan}`;
                        iconHtml = `<i class="dds__icon  dds__icon--alert-info-cir dds__rounded-circle dds__p-1" style="color: #0672CB;" aria-hidden="true"></i>`;
                        linkHtml = `<a href="${thisPcUrl}/drivers" style="text-align:center" class="ancDriversScanNow dds__link--standalone dds__button dds__button--block dds__button--secondary dds__button--sm">${scanNow}</a>`;
                        driversCurrentStage = "post7days";
                    }
                }
                else {
                    criticalRecommendedData = `${runScanAutomatically}`;
                    iconHtml = `<i class="dds__icon  dds__icon--alert-info-cir dds__rounded-circle dds__p-1" style="color: #0672CB;" aria-hidden="true"></i>`;
                    linkHtml = `<a href="${thisPcUrl}/drivers" style="text-align:center" class="ancDriversScanNow dds__link--standalone dds__button dds__button--block dds__button--secondary dds__button--sm">${scanNow}</a>`;
                    driversCurrentStage = "notestever";
                }
            }
        }
        else {
            criticalRecommendedData = `${loadingError}&nbsp;<a href="javascript:void(0);" id="idTryAgainDrivers">${tryAgain}</a>`;
            iconHtml = `<i class="dds__icon  dds__icon--alert-error dds__rounded-circle dds__p-1" style="color:#D0353F"  aria-hidden="true"></i>`;
            driversCurrentStage = "errorfound";
            colXl = 10;
        }
        if (isExceptionEmpty && isDriversDataEmpty && (stCookies != null && stCookies !== "")) {
            iconHtml = `<i class="dds__icon dds__icon--alert-info-cir dds__rounded-circle dds__p-1" style="color: #0672CB;" aria-hidden="true"></i>`;
            if (success.hideDriverAlertforWin11) {
                driversUpdate = `${driverDownloadsWin11}`;
                criticalRecommendedData = `${findDriversStCookie} <span id="dndProductInfo">${getProductName()}</span>`;
                linkHtml = `<a href="${driversData.driverScanInitiateUrl}" class=" dds__link--standalone dds__button dds__button--block dds__button--secondary dds__button--sm">${findDrivers}</a>`;
                driversCurrentStage = "driveralertwin11";
            } else {
                criticalRecommendedData = `${findUpdate} <span id="dndProductInfo">${getProductName()}</span>`;
                linkHtml = `<a href="${driversData.driverScanInitiateUrl}" style="text=align:center" class="ancDriversCheckAgain dds__link--standalone dds__button dds__button--block dds__button--secondary dds__button--sm">${checkAgain}</a>`;
                driversCurrentStage = "sanotinstalled";
            }
        }

        driversHtml = `<div class="dds__row">
                    <div class="dds__col--md-1 dds__mt-1 dds__mx-lg-1 dds__mx-xl-0 dds__px-4xl-3">${iconHtml}</div>
                    <div class="dds__col--md-6 dds__col--lg-10 dds__col--xl-${colXl} dds__px-4xl-3">
                        <div class="dds__row dds__pl-3"><span class="dds__pr-2">${driversUpdate}</span></div>
                        <div class="dds__pl-3 dds__caption" style="color:#636363;">
                            <span class="dds__row">${criticalRecommendedData}</span>
                            ${dateHtml}
                        </div>
                    </div><div class="dds__col--md-5 dds__col--lg-8 dds__col--xl-5 dds__ml--lg-4 dds__ml-xl-0 dds__mt-lg-2">${linkHtml}</div></div>`;

        document.getElementById("txtDriverSpin").classList.add("dds__d-none");
        document.getElementById("divDriversContent").innerHTML = driversHtml;


        if (document.getElementById("idTryAgainDrivers")) {
            document.getElementById("idTryAgainDrivers").addEventListener("click",
                () => {
                    dellMetricsTrackProp20("222.900.200.137", "dnd|drivers|tryagain");
                    document.getElementById("divDriversContent").innerHTML = "";
                    document.getElementById("txtDriverSpin").classList.remove("dds__d-none");
                    AlertDriverTile.getDriverData(function (success) {
                        bindDriversHtml(success);
                    });
                });
        }
        driverAnalytics();
        isDriverLoaded = true;
        setDellMetricsTrackProp20();
    }
    if (success.isSupportAssistAvailable === false) {
        adminAndConsumerMessage(showAdminMessage, isBusinessVersion);
    }
}

function bindDiagnosticsHtml(success) {
    if (success != null && success.DiagnosticData && success.isSupportAssistAvailable == true) {
        let diagnosticsContents = "";
        let alertComponent = "";
        const diagnosticsData = success.DiagnosticData;
        let iconHtml = "";
        let linkHtml = "";
        let dateHtml = "";
        let isDiagnosticsDataEmpty = false;
        let colXl = 6;
        if ((diagnosticsData.lastDiagScanTime === null || diagnosticsData.lastDiagScanTime === '') &&
            (diagnosticsData.alertDiagScanTime === null || diagnosticsData.alertDiagScanTime === '')) {
            isDiagnosticsDataEmpty = true;
        }
        if (stCookies === null || stCookies === "") {
            thisPcUrl = diagnosticsData.diagnosticScanInitiateUrl;
        }
        const isExceptionEmpty = Object.values(success.exception).every(x => x === null || x === '');

        if (isExceptionEmpty) {
            if (!isDiagnosticsDataEmpty) {
                let scanTime = diagnosticsData.alertDiagScanTime;
                if (diagnosticsData.lastDiagScanTime !== null && diagnosticsData.lastDiagScanTime !== '') {
                    scanTime = diagnosticsData.lastDiagScanTime;
                }
                const daysDiff = dateDiff(scanTime);
                if (daysDiff <= 30) {
                    alertComponent = diagnosticsData.alertComponents !== "" ? `${diagnosticsData.alertComponents} ${issueFound}` : `${noIssueFound}`;
                    if (alertComponent === noIssueFound) {
                        iconHtml = `<i class="dds__icon  dds__icon--task-check dds__rounded-circle dds__p-1" style="color:var(--green-600)" aria-hidden="true"></i>`;
                    }
                    else {
                        iconHtml = `<i class="dds__icon  dds__icon--alert-notice dds__rounded-circle dds__p-1" style="color: #CE8D00;"  aria-hidden="true"></i>`;
                    }
                    linkHtml = `<a href="${diagnosticsData.ResultPageURL}" id="ancDiagnosticsViewResult" style="text-align:center" class="dds__link--standalone dds__button dds__button--block dds__button--secondary dds__button--sm">${viewResult}</a>`;
                    dateHtml = `<span class="dds__row">${lastChecked}: ${dateConversion(scanTime)}</span>`;
                    hardwareCurrentStage = "within30days";
                }
                if (daysDiff > 30) {
                    alertComponent = `${lastScan}`;
                    iconHtml = `<i class="dds__icon  dds__icon--alert-info-cir dds__rounded-circle dds__p-1" style="color: #0672CB;" aria-hidden="true"></i>`;
                    linkHtml = `<a href="${thisPcUrl}/diagnose" style="text-align:center" class="ancDiagnosticsScanNow dds__link--standalone dds__button dds__button--block dds__button--secondary dds__button--sm">${scanNow}</a>`;
                    hardwareCurrentStage = "post30days";
                }
            }
            else {
                alertComponent = `${runQuickScan}`;
                iconHtml = `<i class="dds__icon  dds__icon--alert-info-cir dds__rounded-circle dds__p-1" style="color: #0672CB;" aria-hidden="true"></i>`;
                linkHtml = `<a href="${thisPcUrl}/diagnose" style="text-align:center" class="ancDiagnosticsScanNow dds__link--standalone dds__button dds__button--block dds__button--secondary dds__button--sm">${scanNow}</a>`;
                hardwareCurrentStage = "notestever";
            }
        }
        else {
            alertComponent = `${loadingError}&nbsp;<a href="javascript:void(0);" id="idTryAgainDiagnostics">${tryAgain}</a>`;
            iconHtml = `<i class="dds__icon  dds__icon--alert-error dds__rounded-circle dds__p-1" style="color:#D0353F"  aria-hidden="true"></i>`;
            hardwareCurrentStage = "errorfound";
            colXl = 10;
        }

        diagnosticsContents = `<hr class="dds__my-2"/>
                <div class="dds__row">
                    <div class="dds__col--md-1 dds__mt-1 dds__mx-lg-1 dds__mx-xl-0 dds__px-4xl-3">${iconHtml}</div>
                    <div class="dds__col--md-6 dds__col--lg-10 dds__col--xl-${colXl} dds__px-4xl-3">
                        <div class="dds__row dds__pl-3"><span class="dds__pr-2">${hardware}</span></div>
                        <div class="dds__pl-3 dds__caption" style="color:#636363;">
                            <span class="dds__row">${alertComponent}</span>
                            ${dateHtml}
                        </div>
                    </div><div class="dds__col--md-5 dds__col--lg-8 dds__col--xl-5 dds__ml--lg-4 dds__ml-xl-0 dds__mt-lg-2">${linkHtml}</div>
                </div>`;

        document.getElementById("txtDignosticsSpin").classList.add("dds__d-none");
        document.getElementById("divDiagnosticsContent").innerHTML = diagnosticsContents;

        if (document.getElementById("idTryAgainDiagnostics")) {
            document.getElementById("idTryAgainDiagnostics").addEventListener("click",
                () => {
                    dellMetricsTrackProp20("222.900.200.137", "dnd|hardware|tryagain");
                    document.getElementById("divDiagnosticsContent").innerHTML = "";
                    document.getElementById("txtDignosticsSpin").classList.remove("dds__d-none");
                    AlertDiagnosticTile.getDiagnosticData(function (success) {
                        bindDiagnosticsHtml(success);
                    });
                });
        }
        hardwareAnalytics();
        isHardWareLoaded = true;
        setDellMetricsTrackProp20();
    }
    if (success.isSupportAssistAvailable === false) {
        document.getElementById("divDriversDiagnosticsCold").classList.remove("dds__d-none");
        document.getElementById("divDriversDiagnosticsHot").classList.add("dds__d-none");
        isHardWareLoaded = true;
        supportState();
    }
}

function getProductName() {
    const thisPc = sessionStorage.getItem('thispc');
    if (thisPc !== null) {
        const data = JSON.parse(thisPc);
        if (data.name === "null" || data.name === null) {
            return "";
        } else {
            return data.name;
        }
    } else if (stCookies !== null || stCookies !== "") {
        const urlPath = `/support/home/${Dell.Metrics.sc.language}-${Dell.Metrics.sc.country}/productdetect/getproduct`;
        fetch(urlPath,
                {
                    method: "POST",
                    body: JSON.stringify({
                        "ServiceTag": stCookies
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include'
                })
            .then((response) => response.json())
            .then((data) => {
                if (data.productName !== null) {
                    document.getElementById("dndProductInfo").innerHTML = data.productName;
                }
            })
            .catch((ex) => {
                console.error(`Product Name Api Error :- ${ex}`);
            });
    }

    return "";
}


function checkAdminsRights(success) {
    if (success != null && success.exception != null) {
        const exception = success.exception;
        if (exception.message != null && exception.message.toLowerCase().indexOf("non admin user") !== -1) {
            return false;
        }
    }
    return true;
}

function dateConversion(date) {
    const fullDate = new Date(date);
    var monthName = "";

    const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];

    monthName = monthNames[fullDate.getMonth()];

    var twoDigitDate = fullDate.getDate() + "";
    if (twoDigitDate.length === 1) {
        twoDigitDate = "0" + twoDigitDate;
    }
    const currentDate = monthName + " " + twoDigitDate + ", " + fullDate.getFullYear();
    return currentDate;
}

function dateDiff(date) {
    const currentDate = new Date();
    const _date = new Date(date);
    const diffTime = Math.abs(currentDate - _date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}

const renderSaScript = (src) => new Promise((resolve, reject) => {
    const script = document.createElement("script");

    document.getElementById("divDriverDiagScripts").appendChild(script);
    script.onload = resolve;
    script.onerror = reject;
    script.async = true;
    script.src = src;
});

export {
    GetDriverDiagnostics
}

function setDellMetricsTrackProp20() {
    if (isDriverLoaded && isHardWareLoaded) {
        supportStateAnalytics('warm');
        var inc = 0;
        const intervalSupportOptions = setInterval(() => {
            if (typeof (window.dellmetricsTrack) === "function") {
                if (window.dellmetricsTrack) {
                    dellMetricsTrackProp20("222.900.200.126", `dndoffered|drivers-${driversCurrentStage}|hardeware-${hardwareCurrentStage}`);
                }
                clearTimeout(intervalSupportOptions);
            }
            else {
                if (inc == 10) {
                    clearTimeout(intervalSupportOptions);
                }
                inc++;
            }
        }, 1000);
    }
}


function supportState() {
    if (isDriverLoaded && isHardWareLoaded) {
        supportStateAnalytics('cold');
    }
}