import { SetInnerHtml } from "../utilities/common.js";

if (document.getElementById('divHomeDashboard') == null) {
    let ele = document.getElementById("accordionBodyHomeMfe");
    let url = ele.dataset.mfeurl;
    let productSelectorUrl =
        `${url}/support/productsmfe/${Dell.Metrics.sc.language}-${Dell.Metrics.sc.country}/homedashboard?appname=home&searchproduct=true&detectpc=true&supportservice=true&browseallproducts=true&recentlyviewed=true&registereddevices=true`;
    loadMfe(`${productSelectorUrl}`)
        .then((data) => {
            ele.innerHTML = "";
            SetInnerHtml(ele, data);
        })
        .catch((error) => {
            console.error("Loading Home Mfe Error :- ", error);
        });
}

async function loadMfe(url) {
    const response = await fetch(url,
        {
            credentials: 'include',
            headers: {
                'X-Robots-Tag': 'noindex'
            }
        });
    if (response.status === 200) {
        const json = await response.text();
        return json;
    }
}

let path = window.location.pathname;
if (path.indexOf('/products/') > -1) {
    window.BrowseAllProductUrlPush = true;
}

if (path.indexOf('/products') > -1) {
    var len = path.split('/products');
    if (len.length > 1 && len[len.length - 1] === '') {
        setTimeout(function () {
            document.getElementById("accordionBodyHomeMfe").scrollIntoView({ behavior: "smooth", block: "start" });
        }, 1000);
    }
}

window.addEventListener("browse-all-product-push-event", () => {
    const path = window.location.pathname;
    if (path.indexOf('/products/') > -1) {
        if (document.getElementById("btn-homedashboard-browse-all-product")) {
            document.getElementById("btn-homedashboard-browse-all-product").click();
        }
    }
});

window.addEventListener("esupport-detectproduct-data",
    (e) => {
        if (typeof (Storage) !== "undefined") {
            sessionStorage.setItem("thispc", JSON.stringify(e.detail.data.productInfo));
        }
    });