import { SetInnerHtml } from "../utilities/common.js";
import { dellMetricsTrackProp20 } from "../analytics/hero-section.js";

function recentlyViewedData() {

    const topicOfInterestTabsContainer = document.getElementById("tabsContainer");
    let lastUpdatedLabel = "", accessLevel = "";

    if (typeof topicOfInterestTabsContainer !== "undefined" && topicOfInterestTabsContainer !== null) {
        if (topicOfInterestTabsContainer.dataset.lastupdated !== "") {
            lastUpdatedLabel = topicOfInterestTabsContainer.dataset.lastupdated;
        }
        if (topicOfInterestTabsContainer.dataset.accesslevel !== "") {
            accessLevel = topicOfInterestTabsContainer.dataset.accesslevel;
        }
    }

    const rvaStorage = localStorage.getItem("RVA");
    if (rvaStorage !== null) {
        let entriesFromLocalStorage = JSON.parse(rvaStorage).value;
        const count = entriesFromLocalStorage.length;
        entriesFromLocalStorage = entriesFromLocalStorage.reverse();
        let totalCount = 0;
        if (count > 0) {
            let jsRecentlyViewed = "";
            for (let i = 0; i < count; i++) {
                const accessLvl = entriesFromLocalStorage[i].articleAccessLvl;
                if (parseInt(accessLevel) >= parseInt(accessLvl)) {
                    if (totalCount >= 5) {
                        break;
                    }
                    totalCount++;
                    let acc = entriesFromLocalStorage[i].lastupdated;
                    acc = acc.split(' ')[0];
                    const data = `<div class="dds__pb-2 dds__pl-0">
                                    <div class="dds__d-flex recently-viewed-data topics-tab" data-rvpsdataitem="${entriesFromLocalStorage[i].docid}">
                                      <a href="${entriesFromLocalStorage[i].link}" class="dds__link--standalone dds__pr-4">${entriesFromLocalStorage[i].title}</a>
                                      <a href="${entriesFromLocalStorage[i].link}" class="dds__icon dds__icon--arrow-right dds__ml-auto dds__pt-1 rightarrowicon" style="color:var(--blue-600)" aria-hidden="true"></a>
                                 </div>
                                 <div class="dds__row dds__pl-2" style="display: contents;"><div class="dds__font-size-small dds__pt-2" style="color:var(--gray-800);">${lastUpdatedLabel} ${acc}</div></div>
                                </div>`;
                    jsRecentlyViewed += data;
                    if (((count - 1) !== i)) {
                        
                       const hrrline = `<div class="dds__border-bottom borderbottom"></div> <br/>`;
                        jsRecentlyViewed += hrrline;
                    }
                }
            }
            if (document.querySelector("#tab-rvps .dds__tabs__pane__content") != null) {
                SetInnerHtml(document.querySelector("#tab-rvps .dds__tabs__pane__content"), jsRecentlyViewed);
                rvpsPropTagging();
            } else {
                SetInnerHtml(document.getElementById("tab-rvps"), jsRecentlyViewed);
                rvpsPropTagging();    
            }
        }
        function rvpsPropTagging() {
            document.querySelectorAll('.recently-viewed-data').forEach((item) => {
                item.addEventListener('click', function () {
                    dellMetricsTrackProp20("222.900.200.110", `recentlyviewed|${this.dataset.rvpsdataitem}`);
                });
            });
        }
    }
}

export {
    recentlyViewedData
}
