window.AlertDriverTile = function () {
    var alertDriverTile = {};
    var alertCore = AlertCore;
    alertDriverTile.driverTileData = {
        isSupportAssistAvailable: false,
        saType: "",
        clientVersion: "",
        hideDriverAlertforWin11: false,
        driverData: { critical: "", recommended: "", driverScanInitiateUrl: "", lastScanRunTime: "", lastScanDaysPassed: "" },
        exception: { type: null, code: null, message: null }
    };

    alertDriverTile.getDriverData = function (success) {
        try {
            //console.log("method:getDriverData");
            if (typeof alertCore == 'undefined') {
                alertCore = AlertCore;
            }
            alertDriverTile.driverTileData = initializeDriverTileData();
            //priority 1: get the data from session storage
            alertCore.inIt(function () {
                alertDriverTile.driverTileData.driverData.driverScanInitiateUrl = alertCore.homeAlertsDriverUrl;
                alertDriverTile.driverTileData.isSupportAssistAvailable = true;
                alertDriverTile.driverTileData.saType = alertCore.SAType;
                alertDriverTile.driverTileData.clientVersion = alertCore.clientVersion;
                //SaType check
                if (typeof alertCore.SAType != 'undefined' && alertCore.SAType == "SAConsumer") {
                    //Win11SupportCheck
                    //alertDriverTile.driverTileData.hideDriverAlertforWin11 = alertCore.HideDriverScanforWin11();
                    alertCore.HideDriverScanforWin11(function (successcallback) {
                        if (successcallback) {
                            alertDriverTile.driverTileData.hideDriverAlertforWin11 = true;
                            success(alertDriverTile.driverTileData);
                        }
                        else {
                            alertDriverTile.driverTileData.hideDriverAlertforWin11 = false;
                            var dvrSession = alertCore.getDriverSessionData();
                            if (dvrSession != null) {
                                //console.log("alrts data from session");
                                populateDriverTileData(dvrSession, success);
                            } else {
                                //priority 2: get the data from Support Assist call           
                                alertCore.setAlertSAToken(function () {
                                    alertCore.getDriverDetails(function (data) {
                                        //console.log("Inside getDriverDetails - sa call");                            
                                        if (typeof data.LastScanRunTime != null) {
                                            alertCore.setDriverSessionData(data);
                                            populateDriverTileData(data, success);
                                        }
                                        else 
                                            success(alertDriverTile.driverTileData);
                                    }, function (error) {
                                        //console.log("getDriverDetails exeption - " + error);
                                        fillErrorAndLogToSplunk(error);
                                        //handle error, set the exception block of  alertDriverTile.driverTileData   and log to splunk
                                        success(alertDriverTile.driverTileData);
                                    });
                                }, function (errorMsg) {
                                    //handle error   
                                    fillErrorAndLogToSplunk(null, errorMsg);
                                    success(alertDriverTile.driverTileData);
                                    //console.log("sa alerts exeption - " + errorMsg);
                                }, false);
                            }
                            //success(alertDriverTile.driverTileData);
                        }
                    });                     
                }
                else {
                    success(alertDriverTile.driverTileData);
                }
            }, function (initerror) {
                //handle error 
                fillErrorAndLogToSplunk(null, initerror);
                success(alertDriverTile.driverTileData);
                //console.log("sa alerts exeption - " + initerror);
            });
        } catch (driverTileEx) {
            //console.log("DriverTile Exp catch: ", driverTileEx);
            fillErrorAndLogToSplunk(null, driverTileEx.message);
            success(alertDriverTile.driverTileData);
        }
    }
    populateDriverTileData = function (responseData, success) {
        var critical = 0;
        var recommended = 0;
        alertDriverTile.driverTileData.driverData.driverScanInitiateUrl = alertCore.homeAlertsDriverUrl;
        if (responseData != null) {
            if (typeof responseData.LastScanRunTime != 'undefined' && responseData.LastScanRunTime != null) {
                var daysLastScan = AlertCore.dateDiff(responseData.LastScanRunTime);
                if (daysLastScan <= 7) {
                    alertDriverTile.driverTileData.driverData.lastScanDaysPassed = daysLastScan;
                    if (responseData.data != null && responseData.data.Updates != null) {
                        responseData.data.ServiceTag = alertCore.encryptedServiceTag;
                        //calling drivers api to filter demoted drivers
                        alertCore.filteredDrivers(responseData.data,
                            function (demotedResponse) {
                                for (var i = 0; i < demotedResponse.Updates.length; i++) {
                                    switch (demotedResponse.Updates[i].DriverImportanceLevel.toLowerCase()) {
                                        case "recommended": recommended++; break;
                                        case "critical": critical++; break;
                                    }
                                }
                                alertDriverTile.driverTileData.driverData.critical = critical;
                                alertDriverTile.driverTileData.driverData.recommended = recommended
                                alertDriverTile.driverTileData.driverData.lastScanRunTime = responseData.LastScanRunTime;
                                success(alertDriverTile.driverTileData);
                            },
                            function (e) {
                                console.error("drivers filteredResponse failed in homealert - ", e);
                                fillErrorAndLogToSplunk(e);
                                success(alertDriverTile.driverTileData);
                            }
                        );
                    }
                    else
                        success(alertDriverTile.driverTileData);
                }
                else
                    success(alertDriverTile.driverTileData);
            }
            else
                success(alertDriverTile.driverTileData);
        }
        else
            success(alertDriverTile.driverTileData);
    }
    initializeDriverTileData = function () {
        var driverTileData = {
            isSupportAssistAvailable: false,
            saType: "",
            clientVersion: "",
            hideDriverAlertforWin11: false,
            driverData: { critical: "", recommended: "", driverScanInitiateUrl: "", lastScanRunTime: "", lastScanDaysPassed: "" },
            exception: { type: null, code: null, message: null }
        };
        return driverTileData;
    }
    var fillErrorAndLogToSplunk = function (error, errorMsg) {
        var errData = null;
        if (error != null) {
            alertDriverTile.driverTileData.exception = { type: error.type, code: error.errorCode, message: error.message };
            errData = { Source: "From Driver Alert", ErrorCode: error.errorCode, ErrorType: error.type, Message: error.message };
        }
        else {
            alertDriverTile.driverTileData.exception = { type: "GeneralException", code: "GeneralException", message: errorMsg };
            errData = { Source: "From Driver Alert", ErrorCode: "GeneralException", ErrorType: "GeneralException", Message: errorMsg };
        }
        alertCore.logExceptionToSplunk(errData);
    }

    return alertDriverTile;
}();
