import { dellMetricsTrackProp20 } from "../analytics/hero-section.js"


const analyticsProductInfo = () => {
    const ancProductCode = document.getElementById("ancProductCode");

    if (ancProductCode != null) {
        var stCookies = ancProductCode.dataset.stcookies;
        var prev2 = "detectproductoffered";
        if (stCookies === null || stCookies === "null" || stCookies === "") {
            prev2 = "rvpsproductoffered";
        }
        //Link offered
        var inc = 0;
        const intervalSupportOptions = setInterval(() => {
                if (typeof (window.dellmetricsTrack) === "function") {
                    if (window.dellmetricsTrack) {
                        dellMetricsTrackProp20("222.900.200.111", `${prev2}`);
                    }
                    clearTimeout(intervalSupportOptions);
                } else {
                    if (inc == 10) {
                        clearTimeout(intervalSupportOptions);
                    }
                    inc++;
                }
            },
            1000);

        //Click event
        ancProductCode.addEventListener("click",
            function() {
                var prev2 = "detectproduct";
                if (stCookies === null || stCookies === "null" || stCookies === "") {
                    prev2 = "rvpsproduct";
                }
                dellMetricsTrackProp20("222.900.200.112", prev2);
            });
    }

}

export { analyticsProductInfo as AnalyticsProductInfo }