const element = document.getElementById("divPilotBannerMessage");
if (element !== null) {
    DDS.MessageBar(element);
    window.addEventListener("hppalert_sitealert_closeevent",
        (e) => {
            if (e.detail.alertcount === 0) {
                document.querySelector("#divPilotBanner .dds__message-bar").classList.remove("dds__mb-2");
                document.querySelector("#divPilotBanner .dds__message-bar").classList.add("dds__mb-0");
            }
        });
}