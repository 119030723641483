import { dellMetricsTrackProp20 } from "../analytics/hero-section.js";


document.querySelectorAll('.enterpriselinks').forEach((item) => {
    item.addEventListener('click',
        function() {
            var propno = '111.450.100.000', pevname = '';
            if (this.dataset.enterprisetilename === 'ms360') {
                pevname = 'myservice360';
            }
            if (this.dataset.enterprisetilename === 'techdirect') {
                pevname = 'techdirect';
            }
            if (this.dataset.enterprisetilename === 'solve') {
                pevname = 'solve';
            }
            if (this.dataset.enterprisetilename === 'mps') {
                pevname = 'myproductsandservices';
            }
            dellMetricsTrackProp20(propno, pevname);
        });
});


document.querySelectorAll('.enterprise-padding').forEach((item) => {
    item.addEventListener('click',
        () => {
            var enterpriseElement = document.getElementById(item.getAttribute("id"));

            var anchorTag = enterpriseElement.querySelector('a');
            anchorTag.click();
        });
});
