if (window.innerWidth >= 768) {

    (function (loader) {
        loader.baseUrls = loader.baseUrls || {};
        if (loader.components === undefined) { loader.components = []; }
        if (loader.componentsData === undefined) { loader.componentsData = []; }
        if (loader.cmsContents === undefined) { loader.cmsContents = []; }
        var url = "https://www.dell.com/support/components";
        if (window.location.origin.indexOf("wip") !== -1) {
            url = window.location.origin + "/support/components";
        }
        loader.baseUrls['contactUsSliderApi'] = url;
        loader.isAuth = false;
        loader.componentsData['contactus-slider'] = window.ContactUsSliderSchema;
    })(window.Dell.ComponentLoader = window.Dell.ComponentLoader || {});

    // Render contact us slider
    (function (dell) {

        dell.Components = dell.Components || {};
        dell.Components.ContactUsSlider = dell.Components.ContactUsSlider || {};

        (function () {
            var createAnchorTag;
            var createAccordianUi;
            var compData = {
                featurename: '',
                emcserialnumber: '',
                tagid: ''
            };

            this.createSliderView = (response) => {
                let sliderRow = '', divSlider = '', divIcon = '', divContactSlider = '';
                let emcSliderlg = '';

                if (response === null || response === undefined || response.contactUs === null || response.contactUs === undefined || response.contactUs.length <= 0) {
                    this.renderSliderView('');
                    return '';
                }

                if (response !== null && response.contactUs.length > 0) {
                    var divIconContactus = response.isAPJCountry ? 'contactus-slider-HES-Base-APJCountry' : 'contactus-slider-HES-Base';
                    if (response.contactUs.length === 1) {
                        divIcon = `<div style="border-bottom:none;margin-top: -1px;background: #7F234F !important;" class="slider-icon"><a class="slider-icon-anchor" href="#"><span class="${divIconContactus} position-relative dds__py-2 dds__px-3 slider-font" style="margin-top: 7px;${response.isAPJCountry === true ? `margin-left: -4px;` : ``}">${response.contactUsCms.sliderText}</span></a></div>`;
                    }
                    else if (response.contactUs.length === 2) {
                        divIcon = `<div style="border-bottom:none;margin-top: -1px; background: #7F234F !important" class="slider-icon"><a class="slider-icon-anchor" href="#"><span class="${divIconContactus} position-relative dds__pb-2 dds__px-3 slider-font" style="margin-top: 11px;${response.isAPJCountry === true ? `margin-left: -4px;` : ``}">${response.contactUsCms.sliderText}</span></a></div>`;
                    }
                    else {
                        divIcon = `<div style="border-bottom:none;background: #7F234F !important" class="slider-icon"><a class="slider-icon-anchor" href="#"><span class="${divIconContactus} position-relative dds__py-4 dds__px-3 slider-font" style="margin-top: 35px;${response.isAPJCountry === true ? `margin-left: -4px;` : ``}">${response.contactUsCms.sliderText}</span></a></div>`;
                    }
                }

                for (let i = 0, j = response.contactUs.length; i < j; i++) {
                    if ((response.contactUs[i].svg != undefined && response.contactUs[i].svg != null) && response.contactUs[i].svg.length > 0) {
                        sliderRow += `${response.contactUs[i].style.toLowerCase() === 'accordian' ? `<div class="accordion contactus-slider-accordion contact-us-accordion" >` : ``} <div class="slider-row">
                                 ${response.contactUs.length - 1 === i ? `<div style="border-bottom:none;" class="slider-icon"><a class="slider-icon-anchor" href="#"><object class="dti dti-xl dds__text-white m-4" data="${response.contactUs[i].svg}" type="image/svg+xml"></object></a></div>`
                                : `<div class="slider-icon"><a class="slider-icon-anchor" href="#"><object class="dti dti-xl dds__text-white m-4" data="${response.contactUs[i].svg}" type="image/svg+xml"></object></a></div>`
                            }<div class="slider-body dds__flex-column dds__align-items-start dds__justify-content-center" style="width: 250px;">${createAnchorTag(response.contactUs[i])}${response.contactUs[i].subLabel !== '' ? `<p class="contact-subtitle contact-sbutitle-font">${response.contactUs[i].subLabel}</p>` : ``}</div></div>${createAccordianUi(response.contactUs[i])}${response.contactUs[i].style.toLowerCase() === 'accordian' ? `</div>` : ``}`;
                        divIcon = '';
                    }
                    else {
                        if (response.contactUsCms != undefined && response.contactUsCms != null && i == 0) {
                            sliderRow += `${response.contactUs[i].style.toLowerCase() === 'accordian' ? `<div class="accordion contactus-slider-accordion contact-us-accordion" >` : ``} <div class="slider-row">
                            <div class="slider-body dds__pt-2 dds__pb-2" style="${j === 1 ? `border-bottom: 1px solid #CD698B` : `border-bottom:none;`}">${createAnchorTag(response.contactUs[i])}${(response.contactUs[i].subLabel !== '' || response.contactUs[i].hesSubLabel !== '') ? `<p class="contact-subtitle contact-sbutitle-font">${(response.auth.isEmc === true && response.contactUs[i].hesSubLabel !== '' && response.isHESUser === true) ? response.contactUs[i].hesSubLabel : response.contactUs[i].subLabel}</p>` : ``}</div></div>${createAccordianUi(response.contactUs[i])}${response.contactUs[i].style.toLowerCase() === 'accordian' ? `</div>` : ``}`;
                        }
                        else if (i === j - 1) {
                            sliderRow += `${response.contactUs[i].style.toLowerCase() === 'accordian' ? `<div class="accordion contactus-slider-accordion contact-us-accordion" style="border-bottom:1px solid #CD698B" >` : ``}<div class="slider-row">
                                 <div class="slider-body dds__pt-2 dds__pb-2" style="${response.contactUs[i].style.toLowerCase() === 'accordian' ? `border-bottom:none` : `border-bottom: 1px solid #CD698B`}" id="${response.contactUs[i].id.toLowerCase()}">${createAnchorTag(response.contactUs[i])}${response.contactUs[i].subLabel !== '' ? `<p class="contact-subtitle contact-sbutitle-font">${response.contactUs[i].subLabel}</p>` : ``}</div></div>${createAccordianUi(response.contactUs[i], i, j)}${response.contactUs[i].style.toLowerCase() === 'accordian' ? `</div>` : ``}`;
                        }
                        else {
                            sliderRow += `${response.contactUs[i].style.toLowerCase() === 'accordian' ? `<div class="accordion contactus-slider-accordion contact-us-accordion" >` : ``} <div class="slider-row">
                            <div class="slider-body dds__pt-2 dds__pb-2">${createAnchorTag(response.contactUs[i])}${response.contactUs[i].subLabel !== '' ? `<p class="contact-subtitle contact-sbutitle-font">${response.contactUs[i].subLabel}</p>` : ``}</div></div>${createAccordianUi(response.contactUs[i])}${response.contactUs[i].style.toLowerCase() === 'accordian' ? `</div>` : ``}`;
                        }
                    }
                }

                divContactSlider = `<div class="dds__d-flex dds__flex-column dds__w-100" style="border-top:1px solid #CD698B;border-bottom:none !important;">${sliderRow}</div>`;
                divSlider = `<div class="contact-slider ${emcSliderlg} dds__d-none dds__d-flex hidden-xs" style="display: flex !important;">${divIcon + divContactSlider}<div>`;
                this.renderSliderView(divSlider);


                document.querySelectorAll('.contactSliderLink').forEach((item, key) => {
                    item.addEventListener("click", () => {
                        var appCode = key == 0 ? "222.950.210.322" : "222.950.210.323";
                        this.dellMetricsTrackProp20(appCode, "slidertechsupp");
                    });
                });

                for (let i = 0, j = response.contactUs.length; i < j; i++) {
                    if (response.contactUs[i].style.toLowerCase() === 'staticlink') {
                        document.getElementById(response.contactUs[i].id).onclick = function () { Dell.Components.ContactUsSlider.csCallback(response.contactUs[i].id); };
                    }
                }
                document.querySelectorAll('.slider-icon-anchor').forEach((item) => {
                    item.addEventListener('click', function (e) {
                        e.preventDefault();
                    });
                });
            };



            createAccordianUi = (response) => {
                if (response.style.toLowerCase() === 'accordian') {
                    return `<div id="collapse${response.id}" class="collapse js-expanding" data-parent="#divContactUsSlider"><div class="slider-row expandable"><div class="slider-body align-items-start" ${response.id.toLowerCase() === 'accountteam' ? `style="max-height: 150px;"` : ``}><div id="divCs${response.id}">${loadingimg}</div></div></div></div>`;
                }
                else {
                    return ``;
                }
            };

            createAnchorTag = (response) => {
                if (response.style.toLowerCase() === 'staticlink') {
                    return `<a id="${response.id}" target="_blank" href="${response.link}" class="contactSliderLink js-key-navigable slider-body-title stretched-link">${response.label}</a>`;
                }
                else {
                    return ``;
                }
            };

            this.csCallback = (id) => {
                if (typeof ContactUsSliderCallback !== "undefined") {
                    let callBackFun = ContactUsSliderCallback;
                    var data = {
                        "id": id
                    };
                    let returnObj = callBackFun.call(this, data);
                    if (returnObj !== undefined && returnObj === false) {
                        event.preventDefault();
                    }
                }
            };

            this.renderSliderView = (html) => {
                document.getElementById('divContactUsSlider').innerHTML = html;
                this.renderSliderUi();
            };

            this.adjustContactUsBar = () => {
                requestAnimationFrame(function () {
                    var contactsSliderElem = document.getElementById("divContactUsSlider");
                    if (contactsSliderElem) {
                        var contactSlider = document.querySelector('.contact-slider');
                        contactSlider.style.top = '280px';
                        contactSlider.style.display = 'flex!important';
                    }
                }, 0);
            };

            var adjustContactUsFun = this.adjustContactUsBar;
            var createSliderViewFun = this.createSliderView;

            this.getContactUsSlider = () => {
                let urlPath = this.Url();
                let url = `${urlPath.baseUrl}/${urlPath.getContactUsInfo}`;
                fetch(url,
                    {
                        credentials: 'include'
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data !== undefined && data !== null) {
                            if (Dell.ComponentLoader.cmsContents["contactus-slider"] !== '') {
                                contactusCmsContent = data.contactUsCms;
                            }
                            isEmcUser = data.auth.isEmc;
                            createSliderViewFun(data);
                            adjustContactUsFun();
                        }
                    }).catch(function (ex) {
                        console.error('contact us slider api failed ' + ex);
                    });
            };

            this.Url = () => {
                if (Dell.OnlineUtils !== 'undefined' && Dell.OnlineUtils.Lwp !== 'undefined') {
                    let baseUrl = 'https://www.dell.com/support/components';
                    if (window.location.origin.indexOf("wip") !== -1) {
                        baseUrl = window.location.origin + "/support/components";
                    }
                    if (Dell.ComponentLoader.baseUrls !== undefined &&
                        Dell.ComponentLoader.baseUrls["contactUsSliderApi"]) {
                        baseUrl = Dell.ComponentLoader.baseUrls["contactUsSliderApi"];
                    }

                    let featurename = "HomePage";
                    let urls = {
                        baseUrl: baseUrl,
                        getContactUsInfo: `contactusslider/contactusinfo?featureName=${featurename}&assetId=${compData.emcserialnumber}`
                    };

                    return urls;
                }
                return '';
            };


            this.renderSliderUi = () => {
                // Polyfill for forEach():

                if (window.NodeList && !NodeList.prototype.forEach) {
                    NodeList.prototype.forEach = Array.prototype.forEach;
                }

                var contactUsSlider = document.querySelector('.contact-slider');

                var collapsingElements = getCollapsingElements();

                var collapseAll = () => {
                    collapsingElements = getCollapsingElements();
                    collapsingElements.forEach(function (elem) {
                        $(elem).collapse('hide');
                    });
                };

                var openSlider = () => {
                    clearSliderTimeout();
                    contactUsSlider.classList.add('open');
                };

                var openSliderClick = () => {
                    var flag = true;
                    document.querySelectorAll('.contact-slider').forEach((item) => {
                        if (item.matches('.open')) {
                            delaySliderClose();
                            flag = false;
                        }
                    });
                    if (flag) {
                        clearSliderTimeout();
                        contactUsSlider.classList.add('open');
                        this.dellMetricsTrackProp20('222.950.210.330', 'slidercontactusopen');
                    }
                    return false;
                };

                var closeSlider = () => {
                    collapseAll();
                    contactUsSlider.classList.remove('open');
                };

                var sliderTimeout;
                var delaySliderClose = () => {
                    sliderTimeout = window.requestAnimationFrame(() => {
                        closeSlider();
                    },
                        0);
                };

                var clearSliderTimeout = () => {
                    window.clearTimeout(sliderTimeout);
                };

                if (contactUsSlider !== null) {

                    document.querySelectorAll('.slider-icon').forEach((item) => {
                        item.addEventListener('click', function () {
                            openSliderClick();
                        });
                    });

                    document.addEventListener("click", function (event) {
                        if (event.target.closest(".contact-slider") == null) {
                            if (event.target.id !== undefined &&
                                event.target.id === 'TryAgainCsText') {
                                return;
                            }
                            delaySliderClose();
                        }
                    });
                }

            };

            this.getContactUsSlider();
            this.dellMetricsTrackProp20 = (appcode, tabname) => {
                try {
                    if (typeof (window.dellmetricsTrack) === "function") {
                        if (window.dellmetricsTrack) {
                            window.dellmetricsTrack(appcode, tabname);
                        }
                    }
                } catch (ex) {
                    console.error(`${ex}`);
                }
            };

            var getCollapsingElements = () => {
                var collapsingElements = [];

                document.querySelectorAll('.contactus-slider-accordion').forEach((item) => {
                    for (var i = 0; i < item.childElementCount; i++) {
                        if (item.children[i].matches('.js-expanding')) {
                            collapsingElements.push(item.children[i]);
                        }
                    }
                });
                return collapsingElements;
            };

        }).apply(dell.Components.ContactUsSlider);
    })(Dell = window.Dell || {});

}

