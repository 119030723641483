import { dellMetricsTrackProp20 } from "./hero-section.js";

const linkOptions = document.querySelectorAll(".supportOptionsLink");

var offeredPev = '';
for (var i = 0; i < linkOptions.length; i++) {
    if (linkOptions[i].dataset.linkname === 'preferences') {
        offeredPev = "preferencesoffered";
    }
    if (linkOptions[i].dataset.linkname === 'administrativesupport') {
        offeredPev += "|administrativesupportoffered";
    }
}

if (offeredPev !== '') {
    var inc = 0;
    const intervalSupportOptions = setInterval(() => {
        if (typeof (window.dellmetricsTrack) === "function") {
            if (window.dellmetricsTrack) {
                dellMetricsTrackProp20('222.900.200.119', offeredPev);
            }
            clearTimeout(intervalSupportOptions);
        }
        else {
            if (inc === 10) {
                clearTimeout(intervalSupportOptions);
            }
            inc++;
        }
    }, 1000);
}

document.querySelectorAll('.supportOptionsLink').forEach((item) => {
    item.addEventListener('click', function () { 
        if (this.dataset.linkname === "myproductservices") {
            var pevmpsname = this.dataset.useraccess === "True" ? 'viewmyproductsandservices' : 'signin';
            dellMetricsTrackProp20('111.450.100.000', pevmpsname);
        } if (this.dataset.linkname === "myaccount") {
            var pevname = this.dataset.useraccess === "True" ? 'manageaccount' : 'signin';
            dellMetricsTrackProp20('222.900.200.121', pevname);
      } if (this.dataset.linkname === "preferences") {
            dellMetricsTrackProp20('222.900.200.120', 'managepreferences');
        } if (this.dataset.linkname === "administrativesupport") {
            dellMetricsTrackProp20('222.900.200.120', 'contactadminsupport');
        }
    });
});