import { SetInnerHtml } from "../utilities/common.js";
import { dellMetricsTrackProp20 } from "../analytics/hero-section.js";

function mostPopularData() {
    const urlPath = "https://www.dell.com/support/search/api/articles/mostpopularkb";

    const topicOfInterestTabsContainer = document.getElementById("tabsContainer");
    let lastUpdatedLabel = "";

    if (typeof topicOfInterestTabsContainer !== "undefined" && topicOfInterestTabsContainer !== null) {
        if (topicOfInterestTabsContainer.dataset.lastupdated !== "") {
            lastUpdatedLabel = topicOfInterestTabsContainer.dataset.lastupdated;            
        }
    }

    fetch(urlPath,
        {
            method: "POST",
            body: JSON.stringify({
                "Country": Dell.Global.Context.c,
                "Language": Dell.Global.Context.l

            }),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        .then(response => {
            return response.json();
        }).then(data => {
            var count = data.Results != null ? data.Results.length : 0;
            let totalCount = 0;
            if (count > 0) {
                let js = "";
                for (let i = 0; i < count; i++) {
                    if (totalCount >= 5) {
                        break;
                    }
                    totalCount++;
                    const accDate = data.Results[i]["Raw"]["SfLastModifiedDate"];
                    const acc = accDate.substring(0, accDate.length - 8);
                    const b = `<div class="dds__pb-2 dds__pl-0">
                                    <div class="dds__d-flex most-popular-data topics-tab" data-mostpopulardataitem="${data.Results[i]["ArticleNumber"]}">
                                      <a href="${data.Results[i]["ClickUri"]}" class="dds__link--standalone dds__pr-4"> ${data.Results[i]["Title"]}</a>
                                        <a href="${data.Results[i]["ClickUri"]}" class="dds__icon  dds__icon--arrow-right dds__ml-auto dds__pt-1 rightarrowicon" style="color:var(--blue-600)" aria-hidden="true"></a>
                                 </div>
                               <div class="dds__row dds__pl-2" style="display: contents;"><div class="dds__font-size-small dds__pt-2" style="color:var(--gray-800);">${lastUpdatedLabel} ${acc}</div></div></div>`;
                    js += b;
                    if ((count - 1) !== i) {
                        const c = `<div class="dds__border-bottom borderbottom"></div> <br/>`;
                        js += c;
                    }
                }
                if (document.querySelector("#tab-popular .dds__tabs__pane__content") != null) {
                    SetInnerHtml(document.querySelector("#tab-popular .dds__tabs__pane__content"), js);
                    mostpopularPropTagging();
                } else {
                    document.getElementById("tab-popular").classList.add('dds__pl-md-4', 'dds__pr-md-4');
                    SetInnerHtml(document.getElementById("tab-popular"), js);
                    mostpopularPropTagging();
                    
                }

            } else if (count === 0) {
                const noData = `<div
                                  data-dds="message-bar"
                                  role="dialog"
                                  aria-label="mostPopularNoDataMsg"
                                  class="dds__message-bar"
                                  data-dismissible="true">
                                      <i class="dds__icon dds__message-bar__icon dds__icon--alert-info-cir" aria-hidden="true"></i>
                                      <div class="dds__message-bar__content">
                                        ${document.getElementById("tabsContainer").dataset.nodatalbl}
                                      </div>
                                </div>`;
                document.getElementById("tab-popular").classList.remove('dds__pl-md-4', 'dds__pr-md-4');
                if (document.querySelector("#tab-popular .dds__tabs__pane__content") != null) {
                    document.querySelector("#tab-popular .dds__tabs__pane__content").innerHTML = noData;
                } else {
                    document.getElementById("tab-popular").innerHTML = noData;
                }
                
                
            }
            function mostpopularPropTagging() {
                document.querySelectorAll('.most-popular-data').forEach((item) => {
                    item.addEventListener('click', function () {
                        dellMetricsTrackProp20("222.900.200.110", `mostpopular|${this.dataset.mostpopulardataitem}`);
                    });
                });
            }
        }).catch((ex) => {
            const errorData = `<div
                                  data-dds="message-bar"
                                  role="dialog"
                                  aria-describedby="mostpopularerrormsg"
                                  aria-label="mostpopularerrormesg"
                                  aria-labelledby="MessageBar__title-id--820479945"
                                  class="dds__message-bar"
                                  data-dismissible="true"
                                >
                                      <i class="dds__icon dds__message-bar__icon dds__icon--alert-info-cir" aria-hidden="true"></i>
                                      <div class="dds__message-bar__content" id="mostpopularerrormsg">
                                        ${document.getElementById("tabsContainer").dataset.apifailedmsg}
                                        
                                      </div>
                                </div>`;
            document.getElementById("tab-popular").classList.remove('dds__pl-md-4', 'dds__pr-md-4');

            if (document.querySelector("#tab-popular .dds__tabs__pane__content") != null) {
                document.querySelector("#tab-popular .dds__tabs__pane__content").innerHTML = errorData
            } else {
                document.getElementById("tab-popular").innerHTML = errorData;
            }
            

            const tryLink = document.getElementById("tryAgainMostPopular");
            
            if (tryLink !== "undefined" && tryLink !== null) {
                tryLink.addEventListener('click', function () {
                    const loadingHtml = `<div class="dds__loading-indicator dds__loading-indicator--lg" style="height: 300px">
                            <div>
                                <h3 class="dds__pb-5"></h3>
                                <div class="dds__loading-indicator__spinner dds__mb-0"></div>
                                <div class="dds__loading-indicator__label dds__pt-1 dds__text-center">${document.getElementById("tabsContainer").dataset.loaderlbl}</div>
                            </div>
                        </div>`;

                    if (document.querySelector("#tab-popular .dds__tabs__pane__content") != null) {
                        document.querySelector("#tab-popular .dds__tabs__pane__content").innerHTML = "";
                        document.querySelector("#tab-popular .dds__tabs__pane__content").innerHTML = loadingHtml;
                    } else {
                        document.getElementById("tab-popular").innerHTML = "";
                        document.getElementById("tab-popular").innerHTML = loadingHtml;
                    }
                    
                    setTimeout(() => {
                        mostPopularData();
                    }, 200);
                });
            }
            console.error(`most popular articles Error:- ${ex}`);
        });
}

export {
    mostPopularData
}