import { Urls, SupportServicesTileDataRender, SupportServicesErrorTileDataRender } from "./coretiles-utilities.js";
import { SupportServicesErrorTemplateString, SupportServicesTileTemplateString, ponTemplateString } from "./supportservice-coretile-template.js";
import { dellMetricsTrackProp20 } from "../analytics/hero-section.js";
import { SetInnerHtml } from "../utilities/common.js";

const intervalSupportSvc = function(pev2) {
    var inc = 0;
    var intervalSupport = setInterval(() => {
            if (typeof (window.dellmetricsTrack) === "function") {
                if (window.dellmetricsTrack) {
                    dellMetricsTrackProp20('222.900.200.139', pev2);
                }
                clearTimeout(intervalSupport);
            } else {
                if (inc === 10) {
                    clearTimeout(intervalSupport);
                }
                inc++;
            }
        },
        1000);
}

const setSessionShop = function () {
    document.getElementById("ancPon").onclick = () => {
        sessionStorage.setItem("showponoffers", 1);
        dellMetricsTrackProp20('222.900.200.143', 'ponclicked');
    };
}

const supportServiceApiCall = () => {
    const supportSvcTileDiv = document.getElementById('divSupportServiceCoreTile');
    if (supportSvcTileDiv.dataset.stcookie != null && supportSvcTileDiv.dataset.stcookie !== "") {
        var urls = Urls();
        const supportserviceTagUrl = `${urls.encValueUrl}/${supportSvcTileDiv.dataset.stcookie}?appname=warranty`;
        window.fetch(supportserviceTagUrl)
            .then((response) => response.text())
            .then((data) => {
                async function fetchWarrantyAndPon() {
                    const [warrantyResponse, ponResponse] = await Promise.all([
                        window.fetch(`${urls.supportServicesBaseUrl}`,
                            {
                                method: "POST",
                                body: JSON.stringify({
                                    "assetFormat": "servicetag",
                                    "assetId": data,
                                    "appName": "home"
                                }),
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                credentials: 'include'
                            }),
                        window.fetch(`${urls.eligiblePonSupportSvcUrl}/geteligibleponoffers?serviceTag=${data}`)
                    ]);
                    const warranty = await warrantyResponse.json();
                    const pon = await ponResponse.json();
                    return [warranty, pon];
                }

                fetchWarrantyAndPon().then(([warranty, pon]) => {
                    var pev2 = warranty.propC57;
                    if (pon.IsEligiblePONOffers) {
                        intervalSupportSvc(`${pev2}|ponoffered`);
                        //if eligible INWarranty or OOW UI here
                        SupportServicesTileDataRender(ponTemplateString(pon, warranty));
                        setSessionShop();
                    } else {
                        intervalSupportSvc(pev2);
                        // no IW/OOW UI when not eligible
                        SupportServicesTileDataRender(SupportServicesTileTemplateString(warranty));
                    }
                    var reviewPlanId = document.getElementsByClassName("reviewPlanLink");
                    if (reviewPlanId.length === 0) {
                        reviewPlanId = document.getElementsByClassName("reviewLink");
                    }
                    if (reviewPlanId.length > 0) {
                        reviewPlanId[0].onclick = function (e) {
                            e.preventDefault();
                            window.fetch(`${urls.warrantyModalUrl}?assetId=${data}&af=servicetag&appname=home&loadddsscripts=false`)
                                .then(response => response.text())
                                .then(data => {
                                    const ele = document.getElementById("supportSvcWarrantyModal");
                                    ele.innerHTML = "";
                                    SetInnerHtml(ele, data);
                                });
                        };
                    }
                }).catch(ex => {
                    intervalSupportSvc('loaderror');
                    SupportServicesErrorTileDataRender(SupportServicesErrorTemplateString());
                    console.error(`support service api ${ex}`);
                });
            }).catch((ex) => {
                intervalSupportSvc('loaderror');
                SupportServicesErrorTileDataRender(SupportServicesErrorTemplateString());
                console.error(`support service api ${ex}`);
            });
    }
}

export {
    supportServiceApiCall as SupportServiceApiCall
}