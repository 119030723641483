import { SetInnerHtml } from "../utilities/common.js";
import { dellMetricsTrackProp20 } from "../analytics/hero-section.js";

function topSolutionsRecommendedData() {

    const recommendedDivId = document.getElementById("tabsContainer");

    let lastUpdatedLabel = "";
    let cookieVar = "";
    let apcCookie = "";
    let spcCookie = "";
    let isTag = true;
    let visitorId = "";
    let isMlRecommendation = false;

    if (typeof recommendedDivId !== "undefined" && recommendedDivId !== null) {
        if (recommendedDivId.dataset.lastupdated !== null) {
            lastUpdatedLabel = recommendedDivId.dataset.lastupdated;
        }

        if (recommendedDivId.dataset.storolrcookie !== "") {
            cookieVar = recommendedDivId.dataset.storolrcookie;
        }

        if (recommendedDivId.dataset.storolrcookieistag !== "") {
            isTag = recommendedDivId.dataset.storolrcookieistag.toLowerCase();
        }

        if (recommendedDivId.dataset.ismlarticlerecommended != "") {
            isMlRecommendation = recommendedDivId.dataset.ismlarticlerecommended.toLowerCase();
        }

        if (recommendedDivId.dataset.apccookie !== "") {
            apcCookie = recommendedDivId.dataset.apccookie;
        }

        if (recommendedDivId.dataset.spccookie !== "") {
            spcCookie = recommendedDivId.dataset.spccookie;
        }

    }
    var getVisitorCookie = (visitorCookie) => {
        var visitorId = "", cookies = document.cookie.split(";")
        for (var i = 0; i < cookies.length; i++) {
            var cookiePair = cookies[i].split("=");
            if (visitorCookie == cookiePair[0].trim()) {
                var data = decodeURIComponent(cookiePair[1]).split("|");
                if (data !== null && data !== undefined) {
                    visitorId = data[1];
                }
                return visitorId;
            }
        }
        return visitorId;
    }

    try {
        visitorId = getVisitorCookie('s_ecid');
    } catch (ex) {

    }

    var hostName = "https://www.dell.com";
    if (window.location.hostname === 'localhost.dell.com') {
        hostName = "";
    }

    let apcUrl = "", spcUrl = "";
    if (apcCookie !== null && apcCookie !== "") {
        apcUrl = `&apc=${apcCookie}`;
    }
    if (spcCookie !== null && spcCookie !== "") {
        spcUrl = `&spc=${spcCookie}`;
    }

    const actionUrlPath = `${hostName}/support/home/${Dell.Metrics.sc.language}-${Dell.Metrics.sc.country}/topsolutions/gettopsolutionsdata?visitorid=${visitorId}&assetid=${cookieVar}${apcUrl}${spcUrl}&isservicetag=${isTag}&&t=${Date.now()}`;

    fetch(actionUrlPath,
        {
            method: 'GET'
        }).then(response => { return response.json(); })
        .then(topsolutions => {
            if (topsolutions) {
                let isDefaultRecommendation = "false";
                if (typeof topsolutions.isDefault != "undefined" && topsolutions.isDefault != null && topsolutions.isDefault != "") {
                    isDefaultRecommendation = topsolutions.isDefault.toLowerCase();
                }
                if (typeof topsolutions.isMl != "undefined" && topsolutions.isMl != null) {
                    isMlRecommendation = topsolutions.isMl ? "true" : "false";
                    recommendedDivId.dataset.ismlarticlerecommended = topsolutions.isMl;
                }
                const arr = topsolutions.topSolutionArticles;
                const count = arr != null ? arr.length : 0;
                if (count > 0) {
                    let topRecords = "";
                    for (let i = 0; i < count; i++) {

                        const acc = arr[i]["lastModifiedDate"];
                        let convertedTimeDisplay = "";
                        if (acc != null) {
                            const convertedTime = new Date(acc);
                            const convertedTimeMonth = new Date(acc).toLocaleString('en-us',
                                {
                                    month: 'short'
                                });
                            const convertedTimeDay = String(convertedTime.getDate()).padStart(2, "0");
                            const convertedTimeYear = convertedTime.getFullYear();
                            convertedTimeDisplay = convertedTimeDay + " " + convertedTimeMonth + " " + convertedTimeYear;
                        }

                        const data = `<div class="dds__pb-2 dds__pl-0">
                                         <div class="dds__d-flex recommended-data topics-tab" data-recommendeddataitem="${arr[i]["articleNumber"]}">
                                          <a href="${arr[i]["linkAddress"]}" class="dds__link--standalone dds__pr-4">${arr[i]["linkText"]}</a>
                                          <a href="${arr[i]["linkAddress"]}" class="dds__icon  dds__icon--arrow-right dds__ml-auto dds__pt-1 rightarrowicon" style="color:var(--blue-600)" aria-hidden="true"></a>
                                         </div>
                                       <div class="dds__row dds__pl-2" style="display: contents;"><div class="dds__font-size-small dds__pt-2" style="color:var(--gray-800);"> ${lastUpdatedLabel} ${convertedTimeDisplay}</div></div></div>`;
                        topRecords += data;
                        if ((count - 1) !== i) {
                            const bottomRow = `<div class="dds__border-bottom"></div><br/>`;
                            topRecords += bottomRow;
                        }
                    }
                    if (document.querySelector("#tab-recommended .dds__tabs__pane__content") != null) {
                        SetInnerHtml(document.querySelector("#tab-recommended .dds__tabs__pane__content"), topRecords);
                    } else {
                        document.getElementById("tab-recommended").classList.add('dds__pl-md-4', 'dds__pr-md-4');
                        SetInnerHtml(document.getElementById("tab-recommended"), topRecords);
                        document.querySelectorAll('.recommended-data').forEach((item) => {
                            item.addEventListener('click', function () {
                                let pev2 = `recommended|${this.dataset.recommendeddataitem}`;
                                if (isMlRecommendation === "true") {
                                    if (isDefaultRecommendation === "true") {
                                        pev2 = `recommended-ml-default|${this.dataset.recommendeddataitem}`;
                                    } else {
                                        pev2 = `recommended-ml-actual|${this.dataset.recommendeddataitem}`;
                                    }
                                }
                                dellMetricsTrackProp20("222.900.200.110", pev2);
                            });
                        });
                    }
                    const mlRecommendedArticle = new CustomEvent("ml-RecommendedArticle-loaded", {
                        detail: {
                            data: {
                                "isDefaultRecommendation": isDefaultRecommendation,
                                "isMlRecommendation": isMlRecommendation
                            }
                        }
                    });
                    window.dispatchEvent(mlRecommendedArticle);
                } else if (count === 0) {
                    const noData = `<div
                                  data-dds="message-bar"
                                  role="dialog"
                                  aria-describedby="MessageBar__topsolutions"
                                  aria-label="topSolutionsNoDataMsg"
                                  aria-labelledby="MessageBar__title-id--151483980"
                                  class="dds__message-bar"
                                  data-dismissible="true">
                                      <i class="dds__icon dds__message-bar__icon dds__icon--alert-info-cir" aria-hidden="true"></i>
                                      <div id="MessageBar__topsolutions" class="dds__message-bar__content">
                                         ${document.getElementById("tabsContainer").dataset.nodatalbl}
                                      </div>
                                </div>`;
                    document.getElementById("tab-recommended").classList.remove('dds__pl-md-4', 'dds__pr-md-4');
                    document.getElementById("tab-recommended").innerHTML = noData;
                }
            }
        })
        .catch((error) => {
            const errorData = `<div
                                  data-dds="message-bar"
                                  role="dialog"
                                  aria-label="topsolutionserrormsg"
                                  aria-describedby="topsolutionserrormsg"
                                  aria-labelledby="MessageBar__title-id--820479946"
                                  class="dds__message-bar"
                                  data-dismissible="true">
                                      <i class="dds__icon dds__message-bar__icon dds__icon--alert-info-cir" aria-hidden="true"></i>
                                      <div id="topsolutionserrormsg" class="dds__message-bar__content">
                                        ${document.getElementById("tabsContainer").dataset.apifailedmsg2}
                                      </div>
                                </div>`;
            document.getElementById("tab-recommended").classList.remove('dds__pl-md-4', 'dds__pr-md-4');
            if (document.querySelector("#tab-recommended .dds__tabs__pane__content") != null) {
                document.querySelector("#tab-recommended .dds__tabs__pane__content").innerHTML = errorData;
            } else {
                document.getElementById("tab-recommended").innerHTML = errorData;
            }

            const tryLink = document.getElementById("tryAgainRecommended");

            if (tryLink !== "undefined" && tryLink !== null) {
                tryLink.addEventListener('click', function () {
                    const loadingHtml = `<div class="dds__loading-indicator dds__loading-indicator--lg" style="height: 300px">
                            <div>
                                <h3 class="dds__pb-5"></h3>
                                <div class="dds__loading-indicator__spinner dds__mb-0"></div>
                                <div class="dds__loading-indicator__label dds__pt-1 dds__text-center">${document.getElementById("tabsContainer").dataset.loaderlbl}</div>
                            </div>
                        </div>`;
                    if (document.querySelector("#tab-recommended .dds__tabs__pane__content") != null) {
                        document.querySelector("#tab-recommended .dds__tabs__pane__content").innerHTML = "";
                        document.querySelector("#tab-recommended .dds__tabs__pane__content").innerHTML = loadingHtml;

                    } else {
                        document.getElementById("tab-recommended").innerHTML = "";
                        document.getElementById("tab-recommended").innerHTML = loadingHtml;

                    }
                    setTimeout(() => {
                        topSolutionsRecommendedData();
                    }, 500);
                });
            }
            console.error("Get top solution articles Error:-", error);
        });
}


export {
    topSolutionsRecommendedData
}