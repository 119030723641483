// Topic of Interest
import { topSolutionsRecommendedData } from "./topsolutions.js";
import { mostPopularData } from "./mostpopular.js";
import { recentlyViewedData } from "./rvpsdoc.js";
import { dellMetricsTrackProp20 } from "../analytics/hero-section.js";
import { supportStateAnalytics } from "../analytics/supporthome-state.js";


function TopicOfInterest() {

    const ele = document.getElementById("topicofinteresttabs");
    setTimeout(() => {
        DDS.Tabs(ele);
    },
        50);

    var rvaLocalStorage = localStorage.getItem("RVA");
    const recommendedDivId = document.getElementById("tabsContainer");
    var mostPopularTabLoader = document.getElementsByClassName('mostpopularloader')[0];
    const rvpsTabVisibility = document.getElementById('btn-recentlyviewed');
    let cookieVar = "";
    let isMlRecommendation = false;

    if (typeof recommendedDivId !== "undefined" && recommendedDivId !== null) {
        if (recommendedDivId.dataset.storolrcookie !== "") {
            cookieVar = recommendedDivId.dataset.storolrcookie;
        }

        if (recommendedDivId.dataset.ismlarticlerecommended != "") {
            isMlRecommendation = recommendedDivId.dataset.ismlarticlerecommended.toLowerCase();
        }
    }

    if (ele != null) {
        ele.addEventListener("ddsTabsOpenedEvent",
            (e) => {
                if (e.detail.index === 0) {
                    if (cookieVar !== null && cookieVar !== "") {
                        dellMetricsTrackProp20('222.900.200.109', 'recommended');
                    }

                    if (document.querySelectorAll('.topics-tab') !== null &&
                        document.querySelectorAll('.topics-tab') !== "undefined") {
                        if (rvaLocalStorage === null && cookieVar === "") {                          
                            //const api = DDS.Tabs(ele);
                            //api.setActiveTab(2);
                        }
                        //if (document.getElementsByClassName('recommendedloader').length === 1) {
                        //    topSolutionsRecommendedData();
                        //}
                    }
                } else if (e.detail.index === 1) {
                    dellMetricsTrackProp20('222.900.200.109', 'recentlyviewed');
                    const rvpsLoader = document.getElementById('rvpsloader');
                    if (rvaLocalStorage !== null) {
                        if (document.getElementsByClassName('divRvpsLoader').length === 1) {
                            setTimeout(() => {
                                recentlyViewedData();
                            },
                                100);
                        }
                    } else {
                        rvpsLoader.classList.add('dds__d-none');
                        recentlyViewedData();
                    }
                } else if (e.detail.index === 2) {
                    dellMetricsTrackProp20('222.900.200.109', 'mostpopular');
                    if (document.querySelectorAll('.topics-tab') !== null &&
                        document.querySelectorAll('.topics-tab') !== undefined) {
                        if (mostPopularTabLoader !== null) {
                            if (document.getElementsByClassName('mostpopularloader').length === 1) {
                                setTimeout(() => {
                                    mostPopularTabLoader.classList.add('dds__d-none');
                                },
                                    1000);

                                mostPopularData();
                            } else if (document.getElementsByClassName('mostpopularloader').length === 0) {
                                setTimeout(() => {
                                    mostPopularTabLoader.classList.add('dds__d-none');
                                },
                                    1000);
                            }
                        }
                    } else {
                        mostPopularData();
                    }
                }
            });
    }

    //Hiding recently viewed tab if local storage is null


    if (rvaLocalStorage != null) {
        var accessLevel = document.getElementById("tabsContainer").dataset.accesslevel;
        let entriesFromLocalStorage = JSON.parse(rvaLocalStorage).value;
        entriesFromLocalStorage = entriesFromLocalStorage.reverse();
        var showRvp = false;
        for (let i = 0; i < entriesFromLocalStorage.length; i++) {
            const accessLvl = entriesFromLocalStorage[i].articleAccessLvl;
            if (parseInt(accessLevel) >= parseInt(accessLvl)) {
                showRvp = true;
                break;
            }
        }
        if (!showRvp) {
            rvaLocalStorage = null;
        }
    }

    var propName = '';
    if (cookieVar !== "" && rvaLocalStorage !== null) {
        propName = 'recommended|recentlyviewed|mostpopular';
        document.getElementById('btn-recommended').classList.remove('dds__d-none');
        rvpsTabVisibility.classList.remove('dds__d-none');
        document.getElementById('tab-recommended').classList.remove('dds__d-none');
        topSolutionsRecommendedData();
        supportStateAnalytics('warm');
    } else if (cookieVar !== "" && rvaLocalStorage === null) {
        propName = 'recommended|mostpopular';
        document.getElementById('btn-recommended').classList.remove('dds__d-none');
        rvpsTabVisibility.classList.add('dds__d-none');
        document.getElementById('tab-recommended').classList.remove('dds__d-none');
        topSolutionsRecommendedData();
    } else if (cookieVar === "" && rvaLocalStorage === null) {

        if (isMlRecommendation === "true") {
            propName = 'recommended|mostpopular';
            document.getElementById('btn-recommended').classList.remove('dds__d-none');
            document.getElementById('tab-recommended').classList.remove('dds__d-none');
            topSolutionsRecommendedData();
        } else {
            propName = 'mostpopular';
            if (window.innerWidth >= 766) {
                const api = DDS.Tabs(ele);
                api.setActiveTab(2);
            }
            setTimeout(() => {
                mostPopularTabLoader.classList.add('dds__d-none');
            }, 700);
            rvpsTabVisibility.classList.add('dds__d-none');
        }

    } else if (cookieVar === "" && rvaLocalStorage !== null) {
        if (isMlRecommendation === "true") {
            propName = 'recommended|recentlyviewed|mostpopular';
            document.getElementById('btn-recommended').classList.remove('dds__d-none');
            document.getElementById('tab-recommended').classList.remove('dds__d-none');
            rvpsTabVisibility.classList.remove('dds__d-none');
            topSolutionsRecommendedData();
        } else {
            propName = 'recentlyviewed|mostpopular';
            rvpsTabVisibility.classList.remove('dds__d-none');
            if (window.innerWidth >= 766) {
                const api = DDS.Tabs(ele);
                api.setActiveTab(1);
            }
        }
        supportStateAnalytics('warm');
    }

   

    if (isMlRecommendation === "true" && propName.indexOf("recommended") !== -1) {
        window.addEventListener("ml-RecommendedArticle-loaded", (e) => {
            if (typeof e.detail.data.isDefaultRecommendation != "undefined" &&
                (typeof e.detail.data.isMlRecommendation != "undefined" && e.detail.data.isMlRecommendation === "true")) {
                if (e.detail.data.isDefaultRecommendation === "true") {
                    propName = propName.replaceAll("recommended", "recommended-ml-default");
                } else {
                    propName = propName.replaceAll("recommended", "recommended-ml-actual");
                }
                var inc = 0;
                const intervalArticlesProp = setInterval(() => {
                    if (typeof (window.dellmetricsTrack) === "function") {
                        if (window.dellmetricsTrack) {
                            dellMetricsTrackProp20('222.900.200.108', propName);
                        }
                        clearTimeout(intervalArticlesProp);
                    }
                    else {
                        if (inc == 10) {
                            clearTimeout(intervalArticlesProp);
                        }
                        inc++;
                    }
                }, 1000);
            } else {
                var inc = 0;
                const intervalArticlesProp = setInterval(() => {
                    if (typeof (window.dellmetricsTrack) === "function") {
                        if (window.dellmetricsTrack) {
                            dellMetricsTrackProp20('222.900.200.108', propName);
                        }
                        clearTimeout(intervalArticlesProp);
                    }
                    else {
                        if (inc == 10) {
                            clearTimeout(intervalArticlesProp);
                        }
                        inc++;
                    }
                }, 1000);
            }
        });
    } else {
        var inc = 0;
        const intervalArticlesProp = setInterval(() => {
            if (typeof (window.dellmetricsTrack) === "function") {
                if (window.dellmetricsTrack) {
                    dellMetricsTrackProp20('222.900.200.108', propName);
                }
                clearTimeout(intervalArticlesProp);
            }
            else {
                if (inc == 10) {
                    clearTimeout(intervalArticlesProp);
                }
                inc++;
            }
        }, 1000);
    }
}

export { TopicOfInterest }