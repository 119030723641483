window.AlertDiagnosticTile = function () {
    var alertDiagnosticTile = {};
    var alertCore = AlertCore;
    alertDiagnosticTile.DiagnosticTileData = {
        isSupportAssistAvailable: false,
        saType: "",
        clientVersion: "",
        DiagnosticData: { serviceTag: "", alertDiagScanTime: "", alertComponents: "", lastDiagScanTime: "", ResultPageURL: "", diagnosticScanInitiateUrl: ""},
        exception: { type: "", code: "", message: "" }
    }

    alertDiagnosticTile.getDiagnosticData = function (success) {
        try {
            if (typeof alertCore == 'undefined') {
                alertCore = AlertCore;
            }
            clearDiagnosticTileData();
            alertCore.inIt(function () {
                //console.log("Inside inIt function");
                alertDiagnosticTile.DiagnosticTileData.DiagnosticData.diagnosticScanInitiateUrl = alertCore.homeAlertsDiagnoseUrl;
                alertDiagnosticTile.DiagnosticTileData.isSupportAssistAvailable = true;
                alertDiagnosticTile.DiagnosticTileData.saType = alertCore.SAType;
                alertDiagnosticTile.DiagnosticTileData.clientVersion = alertCore.clientVersion;
                if (typeof alertCore.SAType != 'undefined' && alertCore.SAType == "SAConsumer") {
                    var hwalertsData = AlertCore.getDiagnosticSessionData();
                    if (typeof hwalertsData != 'undefined' && hwalertsData != null) {
                        //console.log("Get hw alerts data from session");
                        populateHardwareAlertsData(hwalertsData, alertCore.srCheckUrl, function () {
                            success(alertDiagnosticTile.DiagnosticTileData);
                        }, function (er) {
                            fillErrorAndLogToSplunk(er);
                            success(alertDiagnosticTile.DiagnosticTileData);
                        });
                    }
                    else {
                        //priority 2: get the data from Support Assist call
                        //console.log("Get hw alerts data from SA");
                        alertCore.setAlertSAToken(function () {
                            alertCore.getClientSystemInfo(
                                function (resSAdata) {
                                    if (typeof resSAdata != 'undefined' && resSAdata != null) {
                                        //set the alertDiagnosticTile.DiagnosticTileData
                                        alertCore.setDiagnosticSessionData(resSAdata);
                                        populateHardwareAlertsData(resSAdata, alertCore.srCheckUrl, function () {
                                            success(alertDiagnosticTile.DiagnosticTileData);
                                        }, function (er) { fillErrorAndLogToSplunk(er); success(alertDiagnosticTile.DiagnosticTileData); });
                                    }
                                    else {
                                        //get the data from ODE API - lastdiagscan
                                        populateLastScanData(alertCore.odeApiUrl, function () { success(alertDiagnosticTile.DiagnosticTileData); }, function (error) { fillErrorAndLogToSplunk(error); success(alertDiagnosticTile.DiagnosticTileData); });
                                    }
                                }, function (error) {
                                    //handle error, set the exception block of  alertDiagnosticTile.DiagnosticTileData   and log to splunk
                                    fillErrorAndLogToSplunk(error);
                                    success(alertDiagnosticTile.DiagnosticTileData);
                                });
                        }, function (error) {
                            fillErrorAndLogToSplunk(null, error);
                            success(alertDiagnosticTile.DiagnosticTileData);
                        }, false);
                    }
                }
                else {
                    success(alertDiagnosticTile.DiagnosticTileData);
                }
            }, function (error) {
                fillErrorAndLogToSplunk(null, error);
                success(alertDiagnosticTile.DiagnosticTileData);
            });
        } catch (diagTileEx) {
            //console.log("DiagTile Exp catch: ", driverTileEx);
            fillErrorAndLogToSplunk(null, diagTileEx.message);
            success(alertDiagnosticTile.DiagnosticTileData);
        }
    }
    populateHardwareAlertsData = function (hwalertsresponse, srUrl, success, fail) {
        if (typeof hwalertsresponse.data != 'undefined' && hwalertsresponse.data != null && typeof hwalertsresponse.data.hardwareAlerts != 'undefined' && hwalertsresponse.data.hardwareAlerts != null && hwalertsresponse.data.hardwareAlerts.length !== 0) {
            var daysfromLastScan = alertCore.dateDiff(hwalertsresponse.data.hardwareAlerts[0].Date);
            alertDiagnosticTile.DiagnosticTileData.DiagnosticData.diagnosticScanInitiateUrl = alertCore.homeAlertsDiagnoseUrl;
            if (hwalertsresponse.data.serviceTagData) {
                alertDiagnosticTile.DiagnosticTileData.DiagnosticData.serviceTag = hwalertsresponse.data.serviceTagData.serviceTag;
            }

            if (daysfromLastScan <= 30) {
                var diagnosticids = [];
                var diagnoseDate = [];
                var componentIds = [];

                for (i = 0; i < hwalertsresponse.data.hardwareAlerts.length; i++) {
                    var type = hwalertsresponse.data.hardwareAlerts[i].Type;
                    if (type !== null && (type === 'PCDR' || type === 'PANACEA')) {
                        var daysfromLastScanalerts = alertCore.dateDiff(hwalertsresponse.data.hardwareAlerts[i].Date);
                        if (daysfromLastScanalerts <= 30) {
                            diagnosticids.push(hwalertsresponse.data.hardwareAlerts[i].ODEDiagnosticId);
                            diagnoseDate.push(hwalertsresponse.data.hardwareAlerts[i].Date);
                            componentIds.push(hwalertsresponse.data.hardwareAlerts[i].Component);

                        }
                    }
                }
                if (diagnosticids && diagnosticids.length > 0) {
                    alertCore.httpGETRequest(srUrl, function (data) {
                        if (data == 'true') {
                            var error = { code: "SR Exists", type: "Information", message: "SR# exists for the service tag, hide the hardware alerts" };
                            fail(error);
                        }
                        else {
                            alertDiagnosticTile.DiagnosticTileData.DiagnosticData.alertDiagScanTime = diagnoseDate[0];
                            alertDiagnosticTile.DiagnosticTileData.DiagnosticData.alertComponents = componentIds.length;
                            alertDiagnosticTile.DiagnosticTileData.DiagnosticData.ResultPageURL = alertCore.homeDiagResultUrl + diagnosticids[0];
                            success();
                        }
                    }, function (err) {
                        var error = { code: "Error while verifying SR", type: "Error", message: err };
                        fail(error);
                    })
                }
            } else {
                populateLastScanData(alertCore.odeApiUrl, function () { success(); }, function (err) {
                    fail(err);
                });
            }
        }
        else {
            //get the data from ODE API - lastdiagscan
            populateLastScanData(alertCore.odeApiUrl, function () { success(); }, function (err) {
                fail(err);
            });
        }
    };

    populateLastScanData = function (diagApiUrl, success, fail) {
        alertCore.httpGETRequest(diagApiUrl, function (data) {
            if (typeof data != 'undefined' && data != null) {
                var dayspassedfromLastScan = new Date().getDate() - new Date(data.LastDiagnosticDateTime).getDate();
                if (dayspassedfromLastScan <= 30) {
                    alertDiagnosticTile.DiagnosticTileData.DiagnosticData.lastDiagScanTime = data.LastDiagnosticDateTime;
                    alertDiagnosticTile.DiagnosticTileData.DiagnosticData.ResultPageURL = alertCore.homeDiagResultUrl + data.DiagnosticId;
                    alertDiagnosticTile.DiagnosticTileData.DiagnosticData.serviceTag = data.ServiceTag;
                }
                success();
            } else {
                //console.log("DiagnosticInfo Not available");
                success();
            }
        }, function (e) {
            console.log("populateLastScanData", e);
            var error = { code: "Error while verifying DiagnosticInfo", type: "Error", message: e };
            fail(error);
        });
    };

    var fillErrorAndLogToSplunk = function (error, errorMsg) {
        clearDiagnosticTileData();
        var errData = null;
        if (error != null) {
            alertDiagnosticTile.DiagnosticTileData.exception = { type: error.type, code: error.code, message: error.message };
            errData = { Source: "From Diagnostics Alert", ErrorCode: error.code, ErrorType: error.type, Message: error.message };
        }
        else {
            alertDiagnosticTile.DiagnosticTileData.exception = { type: "GeneralException", code: "GeneralException", message: errorMsg };
            errData = { Source: "From Diagnostics Alert", ErrorCode: "GeneralException", ErrorType: "GeneralException", Message: errorMsg };
        }
        alertCore.logExceptionToSplunk(errData);
    }
    var clearDiagnosticTileData = function () {
        alertDiagnosticTile.DiagnosticTileData = {
            isSupportAssistAvailable: false,
            DiagnosticData: { serviceTag: "", alertDiagScanTime: "", alertComponents: "", lastDiagScanTime: "", ResultPageURL: "", diagnosticScanInitiateUrl: "", saType: "", clientVersion: "" },
            exception: { type: "", code: "", message: "" }
        }
    }
    return alertDiagnosticTile;
}();