// Contact Us Slider
import "./contactus-slider/contact-us-slider.css";
import "./hero-section/herosection.css";
import "./personalized-cards/personalizedcards.css";
import "./topic-of-interest/topicofinterest.css";
import "./support-options/supportoptions.css";
import "./contactus-slider/contactus-slider.js";

// Personalised cards js
import "./personalized-cards/personalisedcards.js";

// Enterprise Links
import "./enterprise-links/enterpriselinks.css";

// Driver Alert & Hardware Alert
import { GetDriverDiagnostics } from "./drivers-diagnostics/drivers-diagnostics.js";
import "./drivers-diagnostics/drivers-diagnostics-analytics.js";

// Support Service
import { SupportServiceApiCall } from "./support-service/supportservicetile-svc.js";

// Service Request
import { ServiceRequestData } from "./service-request/servicerequest.js";

// Home Mfe Accordion
import "./home-mfe/homemfe.js";

// Topic Of Interest
import { TopicOfInterest } from "./topic-of-interest/index.js";

// Analytics
import "./analytics/hero-section.js";
import "./analytics/order-support.js";
import "./analytics/support-options-tiles.js";
import "./analytics/personalized-cards-analytics.js";
import "./analytics/enterprise-links.js";

// Topic Of Interest
import "./micro-survey/microsurvey.js";

// pilot banner
import "./pilot-banner/pilotbanner.js";

// Support State Analytics
import { supportStateAnalytics } from  "./analytics/supporthome-state.js";

// Service Request Call
GetDriverDiagnostics();

// Service Request Call
ServiceRequestData();

// Support Service Call
SupportServiceApiCall();

// Topic Of Interest Call
TopicOfInterest();

// support state Call
supportStateAnalytics();