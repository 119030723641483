window.AlertCore = function () {
    var alertCore = {};
    alertCore.token = null;
    alertCore.alertESC = null;
    alertCore.homeAlertsDriverUrl = null;
    alertCore.getEncryptedTagUrl = "https://www.dell.com/support/components/detectproduct/encvalue/{0}/odeweb";
    alertCore.filterUrl = "https://www.dell.com/support/driver/ips/api/driversbyscan/getdriversbyscan";
    alertCore.findServiceToken = null;
    alertCore.findServiceExpiry = null;
    alertCore.homeBaseUrl = "https://www.dell.com/support/home/{0}/homesaalert/getsatoken";
    alertCore.srCheckUrl = null;
    alertCore.odeApiUrl = null;
    alertCore.homeAlertsDiagnoseUrl = null;
    alertCore.homeDiagResultUrl = null;
    alertCore.encryptedServiceTag = null;
    alertCore.isSASupportedOS = null;
    alertCore.isSASupportedBrowser = null;
    alertCore.SAType = null;
    alertCore.clientVersion = null;
    alertCore.osInfo = null;
    alertCore.productWin11ButNotDellSupported = false;
    alertCore.hideDriversAlert = false;

    alertCore.getEncryptedTag = function (tag, sucess, fail) {
        if (alertCore.encryptedServiceTag != null && alertCore.encryptedServiceTag != "") {
            sucess(alertCore.encryptedServiceTag);
        }
        else {
            alertCore.httpGETRequest(alertCore.getEncryptedTagUrl.replace('{0}', tag),
                function (data) {
                    alertCore.encryptedServiceTag = data;
                    sucess(data);
                }, function (e) { console.error("Get Encrypted Tag Failed", e); fail("Get Encrypted Tag Failed"); });
        }
    }

    alertCore.getAlertToken = function (sucess, fail, encryptedTag, isInit) {
        try {
            //console.log("method: getAlertToken ");
            var tag = (typeof (encryptedTag) !== "undefined" && encryptedTag.length > 0) ? encryptedTag : "";
            if (alertCore.token != "" && alertCore.token != null) {
                alertCore.fillSATokens(alertCore.token, isInit);
                sucess();
            } else {
                var sidVal = window.sessionStorage.getItem("sid");
                var sId = (sidVal != null && sidVal.length > 0) ? 1 : 0;
                var _homeinputParamsUrl = getHomeUrl() + "?code=" + tag + "&sid=" + sId + "&ctime=" + alertCore.getClientTime();
                alertCore.httpGETRequest(_homeinputParamsUrl, function (responseData) {
                    //console.log("method:getAlertToken >> fetch  ", responseData);
                    alertCore.fillSATokens(responseData, isInit);
                    sucess();
                }, function (e) {
                    console.error("token generation error", e);
                    fail("Get Client Security Token Failed");
                });
            }
        }
        catch (e) {
            //console.log("alertCore.getAlertToken ", e);
            fail("Get Client Security Token Failed");
        }
    }

    alertCore.fillSATokens = function (data, isInit) {
        //console.log("method : fillSATokens");
        alertCore.findServiceToken = data.isAliveToken;
        alertCore.findServiceExpiry = data.expires;
        alertCore.isSASupportedOS = data.isSASupportedOS;
        alertCore.isSASupportedBrowser = data.isSASupportedBrowser;
        alertCore.productWin11ButNotDellSupported = data.isProductWin11ButNotDellSupported;
        //console.log("Win11UnSupported:", data.isProductWin11ButNotDellSupported);
        if (!isInit) {
            if (typeof data.expires != "undefined" && data.expires != null && data.expires != "") {
                alertCore.alertESC.tokenExpires.setValue(data.expires);
            }
            if (typeof data.isAliveToken != "undefined" && data.isAliveToken != null && data.isAliveToken != "") {
                alertCore.alertESC.isAliveToken.setValue(data.isAliveToken);
            }
        }
        if ((alertCore.token == null || alertCore.token == "") && typeof data.getClientSystemInfoTokenM != "undefined" && data.getClientSystemInfoTokenM != null && data.getClientSystemInfoTokenM != "") {
            alertCore.token = data;
        }
        if (typeof data.getClientSystemInfoTokenM != "undefined" && data.getClientSystemInfoTokenM != null && data.getClientSystemInfoTokenM != "") {
            alertCore.alertESC.getClientSystemInfoTokenM.setValue(data.getClientSystemInfoTokenM);
        }
        if (typeof data.deviceDriversTokenM != "undefined" && data.deviceDriversTokenM != null && data.deviceDriversTokenM != "") {
            alertCore.alertESC.getDeviceDriversTokenM.setValue(data.deviceDriversTokenM);
        }
        if (typeof data.getDiagScanHistoryTokenM != "undefined" && data.getDiagScanHistoryTokenM != null && data.getDiagScanHistoryTokenM != "") {
            alertCore.alertESC.getDiagScanHistoryTokenM.setValue(data.getDiagScanHistoryTokenM);
        }
        alertCore.homeAlertsDriverUrl = data.homeAlertsDriverUrl;
        alertCore.srCheckUrl = data.homeDiagnosticCaseCheckUrl;
        alertCore.odeApiUrl = data.homeDiagnosticApiUrl;
        alertCore.homeAlertsDiagnoseUrl = data.homeAlertsDiagnosticUrl;
        alertCore.homeDiagResultUrl = data.odeHardwareRedirectUrl;
    }

    alertCore.inIt = function (success, fail) {
        //console.log("method : inIt");
        if (typeof window.clientApi != 'undefined' && typeof window.clientApi.eSupportClientApi != 'undefined') {
            if (alertCore.alertESC == null) {
                alertCore.alertESC = new window.clientApi.eSupportClientApi();
            }
            alertCore.setAlertSAToken(function () {
                if (typeof alertCore.isSASupportedOS != 'undefined' && alertCore.isSASupportedOS == true) {
                    if (typeof alertCore.isSASupportedOS != 'undefined' && alertCore.isSASupportedBrowser == true) {
                        success();
                    } else {
                        fail("Browser not supported");
                    }
                } else {
                    fail("Operating system not supported");
                }

            }, function (eMsg) { fail(eMsg) }, true);
        } else {
            fail("Client API is not available");
        }
    }

    alertCore.setAlertSAToken = function (success, fail, isInit) {
        alertCore.checkSAThenGetTag(function (tag, type, version, osInfo) {
            //Set SA type to the global variable
            alertCore.SAType = type;
            alertCore.clientVersion = version;
            alertCore.osInfo = osInfo;
            //use the tag to encrypt 
            alertCore.getEncryptedTag(tag,
                function (enctag) {
                    //get token from
                    alertCore.getAlertToken(function () {
                        success();
                    },
                        function (eMsg) {
                            fail(eMsg);
                        },
                        enctag,
                        isInit);
                },
                function (e) {
                    console.error("getEncryptedTag failed", e);
                    fail("Tag Encryption Failed");
                })
        },
            function (msg, error) {
                console.error(msg, error);
                fail("SA Available Check Failed");
            });
    }
    alertCore.checkSAThenGetTag = function (successCallbk, failCallbk) {
        //console.log("method: checkSAThenGetTag");
        //find the tag from tagFromSA session storage
        //find SA type & client version from session storage
        var tagFromSA = window.sessionStorage.getItem("tagFromSA");
        var saType = window.sessionStorage.getItem("SAType");
        var clientVer = window.sessionStorage.getItem("clientVersion");
        var osInfo = window.sessionStorage.getItem("osInfo");
        if (tagFromSA && tagFromSA != null && tagFromSA.length > 0 && saType && saType != null && saType.length > 0) {
            //console.log("tag from session");
            successCallbk(tagFromSA, saType, clientVer, osInfo);
        }
        else {
            //or call SA's findServcie remote method and return tag & type
            //set findservice token dynamically and call findServcie remote method
            //here tokenM is not set since tag is not there so once the tag is received from findService then set the tokens again
            alertCore.getAlertToken(function () {
                if (alertCore.findServiceExpiry != null && alertCore.findServiceExpiry != "") {
                    alertCore.alertESC.tokenExpires.setValue(alertCore.findServiceExpiry);
                }
                if (alertCore.findServiceToken != null && alertCore.findServiceToken != "") {
                    alertCore.alertESC.isAliveToken.setValue(alertCore.findServiceToken);
                }
                //console.log("method: findService");
                alertCore.alertESC.findService(function (successdata) {
                    var tagFromSAToken = window.sessionStorage.getItem("tagFromSA");
                    var saTypefromToken = window.sessionStorage.getItem("SAType");
                    var clientVerfromToken = window.sessionStorage.getItem("clientVersion");
                    var osInfofromToken = window.sessionStorage.getItem("osInfo");
                    successCallbk(tagFromSAToken, saTypefromToken, clientVerfromToken, osInfofromToken);
                }, function (error) {
                    //console.error("SA is not available", error);
                    var errorCode = typeof error.errorCode !== "undefined" ? error.errorCode : "pingFailed";
                    failCallbk("SA is not available - " + errorCode, error);
                });
            }, function (eMsg) {
                failCallbk("Alert Token failed", eMsg);
            }, "", true);
        }
    }

    alertCore.getClientSystemInfo = function (successCallbk, failCallbk) {
        var includeServiceTag = true, includeHealthInfo = false, includeCurrentsystemConfig = false;
        alertCore.alertESC.getClientSystemInfo(includeServiceTag, includeHealthInfo, includeCurrentsystemConfig,
            function (successdata) {
                successCallbk(successdata);
            }, function (error) {
                failCallbk(error);
            });
    }
    alertCore.getDriverDetails = function (successCallbk, failCallbk) {
        var runScan = false; var domainName = 'dl.dell.com';
        alertCore.alertESC.getDeviceDrivers(function (successdata) {
            successCallbk(successdata);
        }, function (error) {
            failCallbk(error);
        }, runScan, null, domainName);
    }

    alertCore.getDriverSessionData = function () {
        var lastScanDriverData = null;
        if (sessionStorage['lastDriverScanDetails']) {
            lastScanDriverData = JSON.parse(window.sessionStorage.getItem("lastDriverScanDetails"));
        }
        return lastScanDriverData;
    }
    alertCore.getDiagnosticSessionData = function () {
        var lastScanDiagData = null;
        if (sessionStorage['lastDiagScanDetails']) {
            lastScanDiagData = JSON.parse(window.sessionStorage.getItem("lastDiagScanDetails"));
        }
        return lastScanDiagData;
    }
    alertCore.setDriverSessionData = function (driverData) {
        if (driverData !== null) {
            if (sessionStorage['lastDriverScanDetails']) {
                window.sessionStorage.removeItem("lastDriverScanDetails");
            }
            window.sessionStorage.setItem("lastDriverScanDetails", JSON.stringify(driverData));
        }
    }
    alertCore.setDiagnosticSessionData = function (diagnosticData) {
        if (diagnosticData !== null) {
            if (sessionStorage['lastDiagScanDetails']) {
                window.sessionStorage.removeItem("lastDiagScanDetails");
            }
            window.sessionStorage.setItem("lastDiagScanDetails", JSON.stringify(diagnosticData));
        }
    }

    alertCore.logExceptionToSplunk = function (error) {
        //console.log("From log home alerts to splunk");
        try {
            if (typeof error !== 'undefined' && error !== null) {
                var urlStringtoLog = "https://www.dell.com/support/onlineapi/scan-download-log/home-alerts-flow";
                urlStringtoLog += '?Source=' + error.Source + '&ErrorCode=' + error.ErrorCode + '&ErrorType=' + error.ErrorType + '&Message=' + error.Message;
                if (sessionStorage['clientVersion']) {
                    urlStringtoLog += '&SAversion=' + sessionStorage.getItem("clientVersion");
                }
                if (sessionStorage['SAType']) {
                    urlStringtoLog += '&SAtype=' + sessionStorage.getItem("SAType");
                }
                if (sessionStorage['tagFromSA']) {
                    urlStringtoLog += '&ServiceTag=' + sessionStorage.getItem("tagFromSA");
                }
                alertCore.httpGETRequest(urlStringtoLog, function (data) {
                    console.log('Home alerts failure logged:' + data);
                }, function (e) {
                    console.error('Error in posting home alerts failure', e);
                });
            } else {
                console.log("Empty Error from splunk log");
            }
        } catch (e) {
            console.error("Function: logExceptionSplunk Failure", e);
        }
    }

    alertCore.getClientTime = function () {
        const now = new Date();
        const utcSeconds = now.getTime() + (now.getTimezoneOffset() * 60);
        return Math.round(utcSeconds / 1000);
    };

    alertCore.httpGETRequest = function (url, success, fail) {
        //lets use the options if needed,
        var options = {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8;' }
        }
        var responseFetch = fetch(url);
        responseFetch.then(function (data) {
            return parseResponse(data);
        }).then(function (resData) {
            success(resData);
        }).catch(function (e) {
            fail(e);
        })
    }

    alertCore.httpPOSTRequest = function (url, data, success, fail) {
        var options = {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }
        var responseFetch = fetch(url, options);
        responseFetch.then(function (data) {
            return data.json();
        }).then(function (resData) {
            success(resData);
        }).catch(function (e) {
            fail(e);
        })
    }
    var parseResponse = function (response) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("json") !== -1) {
            return response.json();
        } else {
            return response.text();
        }
    }
    var getHomeUrl = function () {

        if (typeof Dell !== "undefined" && typeof Dell.Metrics !== "undefined" && typeof Dell.Metrics.sc !== "undefined") {
            return alertCore.homeBaseUrl.replace("{0}", Dell.Metrics.sc.language + "-" + Dell.Metrics.sc.country);
        } else {
            return alertCore.homeBaseUrl.replace("{0}/", "");
        }
    }
    alertCore.dateDiff = function (date) {
        const currentDate = new Date();
        const _date = new Date(date);
        const diffTime = Math.abs(currentDate - _date);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }

    //Driver alerts check for win11 platforms.
    var getOSVersion = function (success) {
        if (sessionStorage['osInfo']) {
            var winOSDet = JSON.parse(window.sessionStorage.getItem('osInfo'));
            //OS Version 10.0.19042
            var winversion = parseInt(winOSDet.OSVersion.split('.')[2]);
            var winName = winOSDet.OSName;
            if ((winversion >= 22000 && winName.indexOf("11") > -1) && alertCore.productWin11ButNotDellSupported) {
                success(true);
            }
            else {
                success(false);
            }
        }
        else {
            success(false);
        }
    }
    var isWin11fromUserAgent = function (success) {
        try {
            var majorPlatformVersion = 0;
            if (typeof window.navigator.userAgentData !== "undefined" && typeof window.navigator.userAgentData.getHighEntropyValues !== "undefined") {
                window.navigator.userAgentData.getHighEntropyValues(["platformVersion"]).then(function (ua) {
                    if (typeof window.navigator.userAgentData.platform !== "undefined" && window.navigator.userAgentData.platform === "Windows") {
                        majorPlatformVersion = parseInt(ua.platformVersion.split('.')[0]);
                    }
                    success(majorPlatformVersion >= 13)
                });
            } else {
                console.log("getHighEntropyValues not available", 0);
                success(false);
            }
        } catch (ex) {
            console.log("getHighEntropyValues not available catch", ex);
            success(false);
        }
    }
    alertCore.HideDriverScanforWin11 = function (successcallback) {
        //Disabling Driver Scan for Windows 11
        //console.log("fn:HideDriverScanforWin11");
        //get win11 version from User Agent
        isWin11fromUserAgent(function (success) {
            if (success) {
                if (alertCore.productWin11ButNotDellSupported) {
                    successcallback(true);
                }
                else {
                    successcallback(false);
                }
            } else {
                if (sessionStorage['osInfo']) {
                    getOSVersion(function (success) {
                        if (success) {
                            successcallback(true);
                        }
                        else {
                            successcallback(false);
                        }

                    });
                }
                else {
                    alertCore.checkSAThenGetTag(function (tag, type, version, osInfo) {
                        alertCore.osInfo = osInfo;
                        getOSVersion(function (success) {
                            if (success) {
                                successcallback(true);
                            }
                            else {
                                successcallback(false);
                            }
                        });
                    },
                        function (msg,error) {
                            console.error(msg, error);
                            successcallback(false);
                        });
                }
            }
        });
    }

    //drivers api call to filter demoted drivers
    alertCore.filteredDrivers = (scanResultSA, filteredResponse, onError) => {
        var formData = new FormData();
        var data = new URLSearchParams(formData);
        data.append('scanResult', JSON.stringify(scanResultSA));
        data.append('appId', 'home - alert');
        fetch(alertCore.filterUrl,
            {
                method: "POST",
                headers: new Headers({
                    'X-Requested-With': 'XMLHttpRequest',
                    'GBPageInfo': 'url=' + window.location.href + ';PN=' + Dell.Metrics.sc.pagename + ';Sindex=' + Dell.Metrics.sc.supportappindex,
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }),
                body: data
            })
            .then(response => {
                return parseResponse(response);
            }).then(data => {
                filteredResponse(data);
            }).catch((e) => {
                console.error('filteredDrivers api Failure');
                onError(e);
            });
    }
    return alertCore;
}();