import { dellMetricsTrackProp20 } from "./hero-section.js"

window.supportStateLoaded = true;

const supportStateAnalytics = (pev2) => {
    const supportState = document.getElementById("divSupportState");
    if (supportState != null && window.supportStateLoaded) {
        const state = supportState.dataset.state;
        if (state !== "" || (pev2 != undefined && pev2 !== "")) {
            if (state !== pev2) {
                var stat;
                if (state !== "") {
                    stat = state;
                } else if (pev2 !== "") {
                    stat = pev2;
                }
                window.supportStateLoaded = false;
                var inc = 0;
                const intervalSupportOptions = setInterval(() => {
                        if (typeof (window.dellmetricsTrack) === "function") {
                            if (window.dellmetricsTrack) {
                                dellMetricsTrackProp20("222.900.200.141", `${stat}`);
                            }
                            clearTimeout(intervalSupportOptions);
                        } else {
                            if (inc === 10) {
                                clearTimeout(intervalSupportOptions);
                            }
                            inc++;
                        }
                    },
                    1000);
            }

        }
    }
}

export {
    supportStateAnalytics
}