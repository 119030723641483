import { dellMetricsTrackProp20 } from "../analytics/hero-section.js"

const driversAndDiagnostics = document.getElementById("divDriversAndDiagnostics");
const updateDriversLink = document.getElementById("updateDriversLink");
const scanHardwareLink = document.getElementById("scanHardwareLink");

var stCookies = "";
if (driversAndDiagnostics) {
    stCookies = driversAndDiagnostics.dataset.stcookies;
}
if (updateDriversLink != null) {
    updateDriversLink.addEventListener("click", function () {
        var propCode = "222.900.200.103";
        if (stCookies !== null && stCookies !== "") {
            propCode = "222.900.200.135";
        }
        dellMetricsTrackProp20(propCode, "updatedrivers");
    }); 
}
if (scanHardwareLink != null) {
    scanHardwareLink.addEventListener("click", function () {
        var propCode = "222.900.200.104";
        if (stCookies !== null && stCookies !== "") {
            propCode = "222.900.200.136";
        }
        dellMetricsTrackProp20(propCode, "scanhardware");
    });
}


const driverAnalytics = () => {
    const ancDriversCheckAgain = document.querySelector('.ancDriversCheckAgain');
    const ancDriversScanNow = document.querySelector('.ancDriversScanNow');
    
    if (ancDriversScanNow != null) {
        ancDriversScanNow.addEventListener("click", function () {
            dellMetricsTrackProp20("222.900.200.114", "dnd|drivers|scannow");
        });
    }
    if (ancDriversCheckAgain != null) {
        ancDriversCheckAgain.addEventListener("click", function () {
            dellMetricsTrackProp20("222.900.200.134", "dnd|drivers|checkagain");
        });
    }
    //dellMetricsTrackProp20("222.900.200.134", `dnd|${currentStage}`);
}

const hardwareAnalytics = () => {   
    const ancDiagnosticsViewResult = document.getElementById('ancDiagnosticsViewResult');
    const ancDiagnosticsScanNow = document.querySelector('.ancDiagnosticsScanNow');
    
    if (ancDiagnosticsViewResult != null) {
        ancDiagnosticsViewResult.addEventListener("click", function () {
            dellMetricsTrackProp20("222.900.200.115", "dnd|hardware|viewresult");
        });
    }
    if (ancDiagnosticsScanNow != null) {
        ancDiagnosticsScanNow.addEventListener("click", function () {
            dellMetricsTrackProp20("222.900.200.114", "dnd|hardware|scannow");
        });
    }
}

export {
    driverAnalytics,
    hardwareAnalytics
}



