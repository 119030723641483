import { dellMetricsTrackProp20 } from "../analytics/hero-section.js";

window.LoadMicroSurveyCall = false;
window.IsMicroSurveyLoad = false;

if (document.getElementById("ancTriggerMfb") !== null) {
    document.getElementById("ancTriggerMfb").onclick = function () {
        document.getElementsByTagName('html')[0].style.scrollBehavior = "smooth";
        getMicroSurvey(true, true);
        dellMetricsTrackProp20("222.900.200.140", "whatyouthink");
        return false;
    };
}


function getMicroSurvey(pilotBanner, isScroll) {
    window.LoadMicroSurveyCall = true;
    LoadMicroSurvey(pilotBanner, isScroll);
}

const LoadMicroSurvey = (pilotBanner, isScroll) => {
    var divMicroSurveyScript = document.getElementById("microsurveyScript");
    if (divMicroSurveyScript != null) {
        if (isScroll) {
            scrollToMoreOption();
            if (IsMicroSurveyLoad) {
                vocaas["microsurvey"]["voc_customAttribute"]["EVENT"] = "hppalert";
            }
        }
        if (!window.IsMicroSurveyLoad) {
            const renderMicroSurveyScript = (src) => new Promise((resolve, reject) => {
                const script = document.createElement("script");
                document.getElementById("microsurveyScript").appendChild(script);
                script.onload = resolve;
                script.onerror = reject;
                script.async = true;
                script.src = src;
                script.setAttribute("load-jquery", true);
            });

            Promise.all([renderMicroSurveyScript(divMicroSurveyScript.dataset.url)])
                .then(() => {
                    var inc = 0;
                    const intervalMicroSurvey = setInterval(() => {
                        if (window.vocaas["microsurvey"]["voc_customAttribute"]["APPLICATION_NAME"] === undefined) {
                            window.vocaas["microsurvey"]["voc_customAttribute"]["APPLICATION_NAME"] = "esupport-hpp/home";
                            if (pilotBanner) {
                                window.vocaas["microsurvey"]["voc_customAttribute"]["EVENT"] = "hppalert";
                            }
                            clearTimeout(intervalMicroSurvey);
                        }
                        else {
                            if (inc === 10) {
                                clearTimeout(intervalMicroSurvey);
                            }
                            inc++;
                        }
                    }, 1000);

               
                    window.IsMicroSurveyLoad = true;
                })
                .catch((error) => {
                    window.IsMicroSurveyLoad = false;
                    console.error(`microsurvey not loaded :- ${error}`);
                });
        }
    }
}
function scrollToMoreOption() {
    if (screen.width > 768) {
        if (document.getElementById("divMoreOption") != null) {
            document.getElementById("divMoreOption").scrollIntoView();
        }
    }
    else {
        if (document.getElementById("divmicroSurvey") != null) {
            scrollTo(0, document.getElementById("microsurvey").getBoundingClientRect().top-100);
        }
    }
    
}

if (window.IntersectionObserver) {
    //a callback function that the API executes
    function callbackFunction(entries) { // array of observing elements
        entries.forEach(entry => {
            // The logic to apply for each entry
            if (entry.intersectionRatio > 0) {
                if (!window.LoadMicroSurveyCall) {
                    getMicroSurvey(false, false);
                    return false;
                }
            }
        })
    }

    //Creating the observer
    var observer = new IntersectionObserver(callbackFunction);
    var targetElement = document.querySelector("#divMoreOption");
    //start observing the target node for intersection
    observer.observe(targetElement);
}