
const supportServicesTileTemplateString = (response) => {
    const supportSvcTileDiv = document.getElementById('divSupportServiceCoreTile');

    return `<style>
        .dds__tooltip__pointer::before {
            box-shadow: 2px 2px 0px var(--shadow-color),0 1px 1px var(--shadow-color) !important; 
        }
        </style>
        <div class="dds__card__header">
            <i class="dds__icon dds__icon--warranty dds__card__header__icon"></i>
            <span class="dds__card__header__text dds__pr-lg-3">
                <div class="dds__h5 divCoreTilesHeaders">${supportSvcTileDiv.dataset.supportsvc}</div>
            </span>
            <span>
                <span class="dds__badge dds__badge--success dds__badge--light dds__badge--md dds__mb-lg-2">
                    <span class="dds__badge__label">${supportSvcTileDiv.dataset.thispclabel}</span>
                </span>
            </span>
        </div>
       <div class="dds__card__body dds__pb-2" id="cardbodyidapi" style="padding-top: 11px !important;">
            <div class="dds__justify-content-start">
                         <a href="javascript:void(0);" class="dds__link--standalone dds__mb-2 dds__mr-1 textunderline" aria-label="supportsvcicon" style="cursor: default !important;position: relative;top:-0.5px !important;text-decoration: none !important;">
                         ${(() => {
                                if (response.warrantyIconSvg !== null) {
                                    return `<i class="dds__icon dds__icon--${response.warrantyIconSvg}" style="color:var(--${response.warrantyIconCss})"></i>`;
                                } 
                        })()}
                          </a>
                     ${(() => {
                            if (response.issueWithSupportServicesText !== null) {
                                return `<span>${response.issueWithSupportServicesText}</span>`;
                            } else if (response.warrantyDisplayName !== null && response.warrantyDisplayName !== '') {
                                return `<span style="font-weight:500 !important;">${response.warrantyDisplayName}</span>`;
                            }
                            // 4,5
                            else if (response.warrantyDisplayName === '' && response.warrantyStatusText !== null && response.warrantyEndDate !== null) {
                                return `<span id="">${response.warrantyStatusText} ${response.warrantyEndDate}</span>`;
                            } 
                        })()}
            </div>
                ${(() => {
                        //1
                        if (response.warrantyStatusText === null && response.warrantyEndDate === null) {
                            return `<div class="dds__d-flex dds__pl-4"></div>`;
                            //2,3,6,7,8,9,10
                        } else if (response.warrantyStatusText !== null && response.warrantyEndDate !== null && response.warrantyDisplayName !== '') {
                            return `<div class="dds__d-flex dds__body-2">${response.warrantyStatusText} ${response.warrantyEndDate}</div>`;
                            //4,5
                        } else if (response.warrantyStatusText !== null && response.warrantyEndDate !== null && response.warrantyDisplayName === '') {
                            return `<div class="dds__d-flex dds__pl-4"></div>`;
                        }
                })()}
            ${(() => {
            if (response.retailAssetInfo !== null && response.retailAssetInfo.retailRegisterMessageText !== null) {
                return `<div class="">${response.retailAssetInfo.retailRegisterMessageText} <a href="${response.retailAssetInfo.retailRegisterLink}" class="registerdevicelink">${response.retailAssetInfo.retailRegisterLinkText}</a></div>`;
            }
            else if (response.retailAssetInfo !== null && response.retailAssetInfo.retailRegisterPendingText !== null) {
                return `<div class="">
                           <p>
                                ${response.retailAssetInfo.retailRegisterPendingText}

                                <a href="javascript:void(0);" id="retailregtooltipicon" class="dds__link--standalone" aria-label="registertooltipicon" aria-describedby="supportSvcToolTipBody">
                               
                                 ${(() => {
                        if (response.retailAssetInfo !== null && response.retailAssetInfo.isRetailRegistrationPending === true) {
                            return `<i class="dds__icon dds__icon--alert-info-cir retailregalerticon"></i>`;
                        } 
                                 })()}
                                </a>
                           </p>

                       <div id="supportSvcToolTipBody" data-trigger="#retailregtooltipicon" data-dds="tooltip" class="dds__tooltip" role="tooltip" style="position: absolute !important;top: -13px !important;">
                          <div class="dds__tooltip__pointer dds__dynamic-tooltip-pointer-position tooltippointer" style="left: 137px !important;top: 92% !important;"></div>
                            ${(() => {
                                    if (response.retailAssetInfo !== null && response.retailAssetInfo.retailRegisterToolTipText !== null) {
                                        return `<div class="dds__tooltip__body">
                                             ${response.retailAssetInfo.retailRegisterToolTipText}
                                    </div>`;
                                }
                            })()}
                          </div>
                 </div>`;
            } else {
            return `<div></div>`;
             }
            })()}
        </div>

         <div class="dds__card__action dds__pt-5">
               ${(() => {
                if (response.contactTechnicalSupportText !== null) {
                    return `<a href="${response.contactTechnicalSupportLink}" class="dds__link--standalone dds__button dds__button--secondary dds__button--sm dds__mr-lg-0 contactTechnicalSupportLink" target="_blank">${response.contactTechnicalSupportText}</a>`;
                }
                else if (response.contactTechnicalSupportText === null && response.viewDetailsText === null) {
                    return ``;
                }
                else if (response.viewDetailsText !== null) {
                    return `<a href="${response.ipsOverviewUrl}" class="dds__link--standalone dds__button dds__button--secondary dds__button--sm dds__mr-lg-0 reviewPlanLink">${response.viewDetailsText}</a>`;
                }
               })()}
         </div>`;
}


const ponTemplateString = (ponResponse, warrantyResponse) => {

    const supportSvcTileDiv = document.getElementById('divSupportServiceCoreTile');
    supportSvcTileDiv.dataset.ipsbaseurl = ponResponse.IsInWarranty
        ? supportSvcTileDiv.dataset.ipsbaseurl + '/upgrade'
        : supportSvcTileDiv.dataset.ipsbaseurl + '/overview';

    return `<style>
        .text-truncate {    
            display: -webkit-box;    
            -webkit-box-orient: vertical;
            box-orient: horizontal;
            -webkit-line-clamp: 3;    
            overflow: hidden;
            text-overflow: ellipsis;
        }
       .divTooltipPointer {
            top: 84% !important;
            left: 47% !important;
        }
      @media (max-width: 767.7px) { 
        .reviewLink, .shopNowLink {
           width: auto !important;
            }            
        }
        </style>
        <div class="dds__card__header">
            <i class="dds__icon dds__icon--warranty dds__card__header__icon"></i>
            <span class="dds__card__header__text dds__pr-lg-3">
                <div class="dds__h5 divCoreTilesHeaders">${supportSvcTileDiv.dataset.supportsvc}</div>
            </span>
            <span>
                <span class="dds__badge dds__badge--success dds__badge--light dds__badge--md dds__mb-lg-2">
                    <span class="dds__badge__label">${supportSvcTileDiv.dataset.thispclabel}</span>
                </span>
            </span>
        </div>

        <div class="dds__card__body">
                 <div>
                        <div class="dds__d-md-flex">       
                                      ${(() => {
                            if (warrantyResponse.issueWithSupportServicesText !== null) {
                                return `<div class="dds__pl-0 dds__pr-2 dds__pr-md-0 dds__d-flex">
                                       <i class="dds__icon dds__icon--${warrantyResponse.warrantyIconSvg} dds__pt-1" style="color:var(--${warrantyResponse.warrantyIconCss})"></i>
                                    <div class="dds__pl-3">
                                      <div class="dds__row dds__pl-lg-0 dds__pl-xl-1 dds__pl-2xl-2 dds__pl-4xl-3">
                                          <span class="dds__pr-2">${warrantyResponse.issueWithSupportServicesText}</span>
                                      </div>
                                      <div class="dds__row dds__pl-0 dds__pl-xl-1 dds__pl-2xl-2 dds__pl-4xl-3 dds__caption dds__pt-2" style="color:#636363;padding-bottom:12px!important;">
                                          <a href="${warrantyResponse.contactTechnicalSupportLink}" class="dds__link--standalone dds__button dds__button--secondary dds__button--sm">${warrantyResponse.contactTechnicalSupportText}</a>
                                      </div>
                                   </div>`;
                                 }
                            else {
                                    return `<div class="dds__pl-0 dds__pr-2 dds__pr-md-0 dds__d-flex dds__col--md-7">
                                       <i class="dds__icon dds__icon--${warrantyResponse.warrantyIconSvg} dds__pt-1" style="color:var(--${warrantyResponse.warrantyIconCss})"></i>
                                        <div class="dds__pl-3">
                                          <div class="dds__row dds__pl-lg-0 dds__pl-xl-1 dds__pl-2xl-2 dds__pl-4xl-3">
                                              ${(() => {
                                                     if (warrantyResponse.warrantyDisplayName !== null && warrantyResponse.warrantyDisplayName !== "") {
                                                         return `<div class="dds__pr-2" id="divWarrantyDisplayName" aria-describedby="warrantynametooltipbody">${warrantyResponse.warrantyDisplayName}</div>
                                                                <div id="warrantynametooltipbody" data-trigger="#divWarrantyDisplayName" data-dds="tooltip" class="dds__tooltip" role="tooltip" style="top:-6px !important;">
                                                                    <div class="dds__tooltip__pointer dds__dynamic-tooltip-pointer-position divTooltipPointer"></div>
                                                                    <div class="dds__tooltip__body tooltipbody">${warrantyResponse.warrantyDisplayName} </div>
                                                                </div>`;
                                                    } else {
                                                         return `<div class="dds__pr-2">${warrantyResponse.warrantyStatusText}&nbsp;${warrantyResponse.warrantyEndDate}</div>`;
                                                    }
                                               })()}
                                          </div>
                                          <div class="dds__pl-0 dds__pl-xl-1 dds__pl-2xl-2 dds__pl-4xl-3 dds__caption">
                                        ${(() => {
                                                if (warrantyResponse.warrantyDisplayName !== null && warrantyResponse.warrantyDisplayName !== "") {
                                                    if (warrantyResponse.retailAssetInfo.retailRegisterMessageText !== null && warrantyResponse.retailAssetInfo.retailRegisterLinkText !== null) {
                                                        return `<div class="dds__row" style="color:var(--gray-800);">${warrantyResponse.warrantyStatusText}&nbsp;${warrantyResponse.warrantyEndDate}</div>
                                                                 <div class="dds__row dds__pr-2" style="color:var(--gray-900);">${warrantyResponse.retailAssetInfo.retailRegisterMessageText}</div>
                                                                  <a href="${warrantyResponse.retailAssetInfo.retailRegisterLink}" class="dds__row">${warrantyResponse.retailAssetInfo.retailRegisterLinkText}</a>`;
                                                    } else {
                                                        return `<div class="dds__row" style="color:var(--gray-800);">${warrantyResponse.warrantyStatusText}&nbsp;${warrantyResponse.warrantyEndDate}</div>`;
                                                    }
                                                    
                                                } else {
                                                    return ``;
                                                }
                                            })()}                                                                                                 
                                          </div>
                                       </div>`
                                 }                                        
                                    })()}
                                           </div>
                                    ${(() => {
                                        if (warrantyResponse.contactTechnicalSupportText !== null) {
                                            return ``;
                                        } else {
                                            return `<div class="dds__col--md-5 dds__pl-md-3 dds__pl-4 dds__pr-0 dds__pt-2 dds__pt-md-0" style="margin-left: auto;">
                                                <a href="${warrantyResponse.ipsOverviewUrl}" class="dds__link--standalone dds__button dds__button--block dds__button--secondary dds__button--sm reviewLink dds__text-center" style="line-height: 1.3rem!important;">${warrantyResponse.viewDetailsText}</a>
                                            </div>`;
                                        }
                                    })()}
                                       </div>

                                </div>             

             <div id="divexploreOtherOptionContent">
                  <hr class="dds__my-2">
                    <div class="dds__d-md-flex">
                        <div class="dds__pl-0 dds__pr-2 dds__pr-md-0 dds__d-flex dds__col--xl-7 dds__col--lg-7 dds__col--md-7">
                           <i class="dds__icon dds__icon--shipping dds__pt-1" style="color: #0E0E0E;"></i>
                            <div class="dds__pl-3">
                              <div class="dds__row dds__pl-lg-0 dds__pl-xl-1 dds__pl-2xl-2 dds__pl-4xl-3">
                                  <span class="dds__pr-0">${supportSvcTileDiv.dataset.exploreoptionspon}</span>
                              </div>
                              <div class="dds__pl-0 dds__pl-xl-1 dds__pl-2xl-2 dds__pl-4xl-3 dds__caption" style="color:#636363;">
                                  <span class="dds__row dds__pr-2">${supportSvcTileDiv.dataset.purchaseadditionalsupport}</span>
                              </div>
                           </div>
                       </div>
                        <div class="dds__col--xl-5 dds__col--lg-5 dds__col--md-5 dds__pl-md-3 dds__pl-4 dds__pr-0 dds__pt-2 dds__pt-md-0" style="margin-left: auto;">
                           <a id="ancPon" href="${supportSvcTileDiv.dataset.ipsbaseurl}" class="dds__link--standalone dds__button dds__button--block dds__button--secondary dds__button--sm shopNowLink dds__text-center" style="line-height: 1.3rem!important;">${supportSvcTileDiv.dataset.shopnow}</a>
                        </div>
                   </div>
            </div>
        </div>`;
}

const supportServicesErrorTemplateString = () => {
    const supportSvcTileDiv = document.getElementById('divSupportServiceCoreTile');
    return `<div class="dds__d-flex dds__justify-content-start">
                <div class="dds__text-center dds__justify-content-center" style="padding-top: 2.2px;">
                    <i class="dds__icon dds__icon--alert-error dds__justify-content-center" style="color:var(--red-700);"></i>
                </div>
                <p class="dds__pl-2">${supportSvcTileDiv.dataset.supportsvcfailed}&nbsp<a href="javascript:void(0);" id="SupportSvcTryAgainIdLink">${supportSvcTileDiv.dataset.tryagainlbl}</a></p>
            </div>`;
}

export {
    supportServicesTileTemplateString as SupportServicesTileTemplateString,
    supportServicesErrorTemplateString as SupportServicesErrorTemplateString,
    ponTemplateString
}