
import { SupportServiceApiCall } from "./supportservicetile-svc.js";
import { dellMetricsTrackProp20 } from "../analytics/hero-section.js"

const Urls = () => {
    let country = "", language = "", supportServiceBaseUrl = "", contractServicesUrl = "";

    const supportSvcTileDiv = document.getElementById('divSupportServiceCoreTile');

    if (typeof supportSvcTileDiv !== "undefined" && supportSvcTileDiv !== null) {
        if (supportSvcTileDiv.dataset.language !== "") {
            language = supportSvcTileDiv.dataset.language;
        }
        if (supportSvcTileDiv.dataset.country !== "") {
            country = supportSvcTileDiv.dataset.country;
        }
        if (supportSvcTileDiv.dataset.supportservicebaseurl !== "") {
            supportServiceBaseUrl = supportSvcTileDiv.dataset.supportservicebaseurl;
        }
        if (supportSvcTileDiv.dataset.contractservicesurl !== "") {
            contractServicesUrl = supportSvcTileDiv.dataset.contractservicesurl;
        }
    }

    return {
        "supportServiceUrl": `${supportServiceBaseUrl}/support/warranty/${language}-${country}`,
        "encValueUrl": `${supportServiceBaseUrl}/support/components/detectproduct/encvalue`,
        "supportServicesBaseUrl": `${supportServiceBaseUrl}/support/contractservices/${language}-${country}/entitlement/contractservicesapi/v1`,
        "eligiblePonSupportSvcUrl": `${supportServiceBaseUrl}/support/components/servicestore/${language}-${country}/servicestore`,
        "warrantyModalUrl": `${contractServicesUrl}/support/contractservices/${language}-${country}/warrantydetailsinit`
    }
}

//prop tagging manage support svc btn
if (document.querySelector('.managesupportsvcbtn') !== null) {
    document.querySelector('.managesupportsvcbtn').onclick = function () {
        dellMetricsTrackProp20('222.900.200.106', 'managesupportservices');
    }
}

const SupportServicesTileDataRender = (html) => {

    const supportSvcDiv = document.getElementById("divSupportSvcContent");
    if (typeof supportSvcDiv !== "undefined" && supportSvcDiv !== null) {
        supportSvcDiv.innerHTML = html;
    }

    const toolTipEle = document.getElementById("supportSvcToolTipBody");
    if (toolTipEle !== null) {
        DDS.Tooltip(toolTipEle);
    }

    const divWarrantyDisplayName = document.getElementById("divWarrantyDisplayName");
    if (divWarrantyDisplayName !== null) {
        if (divWarrantyDisplayName.textContent.length >= 34) {
            divWarrantyDisplayName.classList.add("text-truncate");
            if (divWarrantyDisplayName.scrollHeight > divWarrantyDisplayName.offsetHeight) {
                DDS.Tooltip(document.getElementById("warrantynametooltipbody"));
            }
        }
    }

    const retailRegIcon = document.querySelector(".retailregalerticon");
    if (retailRegIcon !== null) {
        retailRegIcon.onmouseover = () => {
            dellMetricsTrackProp20("222.900.200.133", "retailregistericon");
        }
    }

    const contactTechnicalSupportLink = document.querySelector(".contactTechnicalSupportLink");

    if (contactTechnicalSupportLink !== null) {
        contactTechnicalSupportLink.addEventListener("click", function () {
            dellMetricsTrackProp20("222.900.200.131", "contacttechnicalsupport");
        });
    }

    const linkItems = document.getElementsByClassName('reviewPlanLink')[0];
    if (linkItems !== undefined && linkItems !== null) {
        linkItems.addEventListener('click', function () {
            dellMetricsTrackProp20("222.900.200.117", "reviewserviceplan");
        });
    }    

    const registerdevicelink = document.querySelector('.registerdevicelink');
    if (registerdevicelink !== null) {
        registerdevicelink.onclick = () => {
            dellMetricsTrackProp20("222.900.200.130", "registeryourdevice");
        }
    }
};

const SupportServicesErrorTileDataRender = (html) => {

    const supportSvcCoreTileDiv = document.getElementById('divSupportServiceCoreTile');
    const supportSvcErrorDiv = document.getElementById("divSupportServiceBodyId");

    if (typeof supportSvcErrorDiv !== "undefined" && supportSvcErrorDiv !== null) {
        supportSvcErrorDiv.innerHTML = html;
    }

    const tryLink = document.getElementById("SupportSvcTryAgainIdLink");

    if (tryLink !== "undefined" && tryLink !== null) {
        tryLink.addEventListener('click', function () {
            dellMetricsTrackProp20("222.900.200.132", "tryagain");
            supportSvcErrorDiv.innerHTML = "";
            supportSvcErrorDiv.innerHTML = `<div class="dds__loading-indicator dds__loading-indicator--sm" style="height: auto;justify-content: left !important;display: flex;margin-inline-end: auto;">
                    <div class="dds__loading-indicator__spinner"></div>
                  <div class="dds__loading-indicator__label dds__pl-2 dds__mt-0">${supportSvcCoreTileDiv.dataset.loadingdatalbl}</div>
            </div>`;
            setTimeout(() => {
                SupportServiceApiCall();
            }, 500);
        });
    }
};

export {
    Urls,
    SupportServicesTileDataRender,
    SupportServicesErrorTileDataRender
}