import { SetInnerHtml } from "../utilities/common.js";
import { dellMetricsTrackProp20 } from "../analytics/hero-section.js";

const servicesRequestErrorTemplateString = () => {
    const servicerequest = document.getElementById('divServiceRequest');
    return `<div id="errorspinner" class="dds__loading-indicator dds__loading-indicator--sm dds__pt-2 dds__d-none" style="height: auto;justify-content: left !important;display: flex;margin-inline-end: auto;">
                    <div class="dds__loading-indicator__spinner"></div>
                  <div class="dds__loading-indicator__label dds__pl-2 dds__mt-0"></div>
            </div>
            <div class="dds__d-flex dds__justify-content-start">
                <div class="dds__text-center dds__justify-content-center" style="padding-top: 2px;">
                <i class="dds__icon dds__icon--alert-error dds__mt-1 dds__justify-content-center" style="color:var(--red-700);"></i>
                </div>
                <p aria-describedby="" class="dds__pl-2">${servicerequest.dataset.servicesvcfailed} <a href="javascript:void(0);" id="tryAgainIdLink"> ${servicerequest.dataset.tryagainlbl}</a></p>
            </div>`;
}
const serviceReqErrorTileDataRender = (html) => {
    const servicerequest = document.getElementById('divServiceRequest');
    const serviceReqSvcErrorDiv = document.getElementById("divServiceRequestMain");
    if (typeof servicerequest !== "undefined" && servicerequest !== null) {
        serviceReqSvcErrorDiv.innerHTML = html;
    }
    const tryLink = document.getElementById("tryAgainIdLink");
    if (tryLink != null) {
        tryLink.addEventListener('click',
            function () {
                dellMetricsTrackProp20("222.900.200.129", "tryagain");
                serviceReqSvcErrorDiv.innerHTML = "";
                serviceReqSvcErrorDiv.innerHTML = `<div id="" class="dds__loading-indicator dds__loading-indicator--sm" style="height: auto;justify-content: left !important;display: flex;margin-inline-end: auto;">
                     <div class="dds__loading-indicator__spinner"></div><div class="dds__loading-indicator__label dds__pl-2 dds__mt-0">${servicerequest.dataset.loadingdatalbl}</div></div>`;
                setTimeout(() => { ServiceRequestData(); }, 1000);
            });
    }
};

const intervalSupportReq = function (pev2) {
    var inc = 0;
    var intervalSupport = setInterval(() => {
            if (typeof (window.dellmetricsTrack) === "function") {
                if (window.dellmetricsTrack) {
                    dellMetricsTrackProp20('222.900.200.138', pev2);
                }
                clearTimeout(intervalSupport);
            } else {
                if (inc === 10) {
                    clearTimeout(intervalSupport);
                }
                inc++;
            }
        },
        1000);
}

function ServiceRequestData() {
    const urlPath = `https://www.dell.com/support/mps/${Dell.Metrics.sc.language}-${Dell.Metrics.sc.country}/components/v2/servicerequests`;
    fetch(urlPath,
            {
                credentials: 'include'
            })
        .then((response) => response.text())
        .then((data) => {
            SetInnerHtml(document.getElementById("divServiceRequestMain"), data);
        })
        .catch((ex) => {
            intervalSupportReq('loaderror');
            serviceReqErrorTileDataRender(servicesRequestErrorTemplateString());
            console.error(`service request API not loaded :- ${ex}`);
        });
}

export {
    ServiceRequestData
}
