const dellMetricsTrackProp20 = (appcode, tabname) => {
    try {
        if (typeof (window.dellmetricsTrack) === "function") {
            if (window.dellmetricsTrack) {
                window.dellmetricsTrack(appcode, tabname);
            }
        }
    } catch (ex) {
        console.error(`${ex}`);
    }
}

const linkCreateAccount = document.querySelector('.btncreateacclink');

const signInBtn = document.getElementById('signInBtn');

const visitBusinessLink = document.getElementById('businessvisitlink'); 

if (linkCreateAccount != null) {
    linkCreateAccount.addEventListener("click",
        function() {
            dellMetricsTrackProp20('222.900.200.100', 'createanaccount');
        });
}

if (signInBtn != null) {
    signInBtn.addEventListener("click", function () {
        dellMetricsTrackProp20('222.900.100.250', 'signin');
    });
}

if (visitBusinessLink != null) {
    visitBusinessLink.addEventListener("click",
        function () {
            dellMetricsTrackProp20('222.900.200.101', 'visitbusinesssite');
        });
}

export {
    dellMetricsTrackProp20
}